import { useEffect, useRef } from 'react';
import { useRecoilState, useRecoilValue } from 'recoil';
import { selectedInfoState, statesInfoState } from '../../atoms';
import { defaultCountry, IState } from '../../data';

import mapboxgl from 'mapbox-gl'; // eslint-disable-line import/no-webpack-loader-syntax
import { useNavigate } from 'react-router-dom';
import { config } from '../../Config';
import { default as usStates } from '../../us_states';

export default function Map() {
  let navigate = useNavigate();
  mapboxgl.accessToken = config.mapbox.token;
  const statesRef = useRef<any>(null);
  const mapContainer = useRef<any>(null);
  const map: any = useRef<mapboxgl.Map | null>(null);
  const states = useRecoilValue(statesInfoState);
  const [selectedState, setSelectedState] = useRecoilState(selectedInfoState);
  const currentState: any =
    selectedState &&
    states.find((state: any) => {
      return state.state === selectedState.state;
    });

  let hoveredStateId: any = null;

  useEffect(() => {
    if (states.length !== 0) {
      statesRef.current = states;
    }
  }, [states]);

  const onClickSelectMap = ({ id }: { id: string }) => {
    if (statesRef.current === null) return;
    setSelectedState(defaultCountry);
    setTimeout(() => {
      if (statesRef.current.length !== 0) {
        const findStateById = statesRef.current.find((state: IState) => {
          return state.state_id == id;
        });
        setSelectedState(findStateById);
        navigate(`/${findStateById.state}`);
      }
    }, 100);
  };

  useEffect(() => {
    if (map.current) return; // initialize map only once

    map.current = new mapboxgl.Map({
      container: mapContainer.current,
      style: config.mapbox.style,
      center: [-98.2883, 41.1289],
      zoom: 4.7,
      pitch: 50,
      bearing: 0,
    });

    map.current.on('load', async () => {
      if (!map.current) return; // initialize map only once

      usStates.features.forEach((feature: any) => {
        const sourceId = `source-${feature.properties['STATE_ID']}`;

        // todo - make this dynamic from the data
        const highlightedStateIds = [
          'source-28',
          'source-29',
          'source-30',
          'source-31',
        ];
        const defaultFillColor = '#006a99';
        const highlightFillColor = '#ffdd00';

        map.current.addSource(sourceId, {
          type: 'geojson',
          data: feature,
        });

        const setFillColor =
          highlightedStateIds.indexOf(sourceId) === -1
            ? defaultFillColor
            : highlightFillColor;

        map.current.addLayer({
          id: sourceId,
          type: 'fill',
          source: sourceId,
          paint: {
            'fill-color': setFillColor,
            'fill-opacity': 0.5,
          },
        });
      });

      map.current.addSource('states', {
        type: 'geojson',
        data: 'https://docs.mapbox.com/mapbox-gl-js/assets/us_states.geojson',
      });

      // The feature-state dependent fill-opacity expression will render the hover effect
      // when a feature's hover state is set to true.
      map.current.addLayer({
        id: 'state-fills',
        type: 'fill',
        source: 'states',
        layout: {},
        paint: {
          'fill-color': '#006a99',
          'fill-opacity': [
            'case',
            ['boolean', ['feature-state', 'hover'], false],
            0.5,
            0.3,
          ],
        },
      });

      map.current.addLayer({
        id: 'state-borders',
        type: 'line',
        source: 'states',
        layout: {},
        paint: {
          'line-color': '#006a99',
          'line-width': 0.5,
        },
      });

      // When the user moves their mouse over the state-fill layer, we'll update the
      // feature state for the feature under the mouse.
      map.current.on('mousemove', 'state-fills', (e: any) => {
        if (e.features.length > 0) {
          if (hoveredStateId !== null) {
            map.current.setFeatureState(
              { source: 'states', id: hoveredStateId },
              { hover: false }
            );
          }
          hoveredStateId = e.features[0].id;
          map.current.setFeatureState(
            { source: 'states', id: hoveredStateId },
            { hover: true }
          );
        }
      });

      // When the mouse leaves the state-fill layer, update the feature state of the
      // previously hovered feature.
      map.current.on('mouseleave', 'state-fills', () => {
        if (hoveredStateId !== null) {
          map.current.setFeatureState(
            { source: 'states', id: hoveredStateId },
            { hover: false }
          );
        }
        hoveredStateId = null;
      });

      map.current.on('mouseenter', 'state-fills', () => {
        map.current.getCanvas().style.cursor = 'pointer';
      });

      map.current.on('click', 'state-fills', (e: any) => {
        if (e.features.length > 0) {
          const id = e.features[0].properties.STATE_ID;
          const sourceId = `source-${id}`;
          map.current.setPaintProperty(sourceId, 'fill-opacity', 0.5);
          onClickSelectMap({ id });
        }
      });
    });
  }, [map, mapContainer, selectedState, states]);

  useEffect(() => {
    if (states.length !== 0 && map.current) {
      if (currentState) {
        map.current.flyTo({
          center: [currentState.longitude, currentState.latitude],
          // These options control the flight curve, making it move
          // slowly and zoom out almost completely before starting
          // to pan.
          speed: 1.5, // make the flying slow
          curve: 1.5, // change the speed at which it zooms out

          // This can be any easing function: it takes a number between
          // 0 and 1 and returns another number between 0 and 1.
          easing: (t: any) => t,

          // this animation is considered essential with respect to prefers-reduced-motion
          essential: true,
          padding: { left: 0, top: 0, bottom: 0, right: 350 },
        });

        if (currentState.state_id !== '0') {
          const sourceId = `source-${currentState.state_id}`;
          map.current.setPaintProperty(sourceId, 'fill-opacity', 0.5);
        }
      } else {
        usStates.features.forEach((feature: any) => {
          const sourceId = `source-${feature.properties['STATE_ID']}`;
          map.current.setPaintProperty(sourceId, 'fill-opacity', 0.1);
        });
      }
    }
  }, [map, states, selectedState, currentState]);

  return <div ref={mapContainer} className="map-container" />;
}
