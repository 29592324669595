import { Dialog, Transition } from '@headlessui/react';
import { Fragment, useCallback, useEffect, useState } from 'react';
import { IState, defaultCountry } from '../../data';
import { selectedInfoState, showPrintView, statesInfoState } from '../../atoms';

import { Component as Button } from '../Buttton';
import { Component as ImageLoader } from '../ImageLoader';
import { Component as Tooltip } from '../Tooltip';
import { XIcon } from '@heroicons/react/outline';
import { cleanupContent } from '../../utils';
import mapBG from '../../images/map-bg.jpg';
import { useKey } from 'react-use';
import { useRecoilState } from 'recoil';

function SliderOut() {
  const [states, _] = useRecoilState(statesInfoState);
  const [selectedState, setSelectedState] = useRecoilState(selectedInfoState);
  const [isOpenPrintView, setIsOpenPrintView] = useRecoilState(showPrintView);
  const [open, setOpen] = useState(false);

  const onOpenPDFView = () => {
    setIsOpenPrintView(true);
  };

  const onCloseDialog = useCallback(() => {
    setOpen(!open);
    setSelectedState(defaultCountry);
  }, [open, setOpen, setSelectedState]);

  const callToActionCLI = () => {
    window.location.replace('https://survivorspace.org/civil-litigation');
  };

  useKey('Escape', () => {
    setOpen(false);
    setTimeout(() => {
      setSelectedState(defaultCountry);
    }, 500);
  });

  useEffect(() => {
    if (selectedState.id !== 0 && states.length !== 0) {
      const matchState: any = states.find(
        (state: IState) => state.state_id === selectedState.state_id
      );
      setSelectedState(matchState);
      setOpen(true);
    }
  }, [selectedState, setOpen, states, setSelectedState]);

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog open={open} as="div" className="relative z-10" onClose={() => {}}>
        <div className="fixed">
          <div className="absolute">
            <div className="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10 sm:pl-16">
              <Transition.Child
                as={Fragment}
                enter="transform transition ease-in-out duration-500 sm:duration-700"
                enterFrom="translate-x-full"
                enterTo="translate-x-0"
                leave="transform transition ease-in-out duration-500 sm:duration-700"
                leaveFrom="translate-x-0"
                leaveTo="translate-x-full"
              >
                <Dialog.Panel className="pointer-events-auto w-screen max-w-2xl">
                  <div className="flex h-full flex-col overflow-y-scroll bg-white shadow-xl">
                    <div
                      className="px-4 py-6 sm:px-6 bg-center bg-cover"
                      style={{
                        backgroundImage: `url(${mapBG})`,
                      }}
                    >
                      <div className="flex items-start justify-between">
                        <h2
                          id="slide-over-heading"
                          className="text-lg font-medium text-white"
                        >
                          State Details
                        </h2>
                        <div className="ml-3 flex h-7 items-center">
                          <a
                            href="#"
                            className="rounded-md outline-0"
                            onClick={onCloseDialog}
                          >
                            <span className="sr-only">Close panel</span>
                            <XIcon
                              className="h-6 w-6 text-white"
                              aria-hidden="true"
                            />
                          </a>
                        </div>
                      </div>
                    </div>
                    <div>
                      <div className="pb-1 sm:pb-6">
                        <div>
                          <div className="relative h-96 sm:h-96">
                            <ImageLoader
                              className="absolute h-full w-full object-cover"
                              errorImg="https://media.tenor.com/Tu0MCmJ4TJUAAAAC/load-loading.gif"
                              placeholderImg="https://media.tenor.com/Tu0MCmJ4TJUAAAAC/load-loading.gif"
                              src={selectedState.image}
                            />
                          </div>
                          <div className="mt-6 px-4 sm:mt-8 sm:flex sm:items-end sm:px-6">
                            <div className="sm:flex-1 flex justify-between align-center">
                              <div>
                                <div className="flex items-center">
                                  <h2 className="text-xl font-bold accent-blue sm:text-2xl">
                                    {selectedState.title}
                                  </h2>
                                </div>
                                <p className="text-sm  italic">
                                  {selectedState.modified}
                                </p>
                              </div>
                              <div>
                                <Button
                                  className="inline-flex align-center"
                                  onClick={onOpenPDFView}
                                >
                                  <div className="ml-2">Print View</div>
                                </Button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="px-4 pt-5 pb-5 sm:px-0 sm:pt-0">
                        <dl className="space-y-8 sm:space-y-6 sm:px-6">
                          <div>
                            <dt className="text-primary-blue text-sm sm:flex-shrink-0">
                              <Tooltip
                                label={
                                  <span className="flex items-center bg-slate-700 text-white text-sm font-medium py-1 px-2 rounded-sm shadow-md">
                                    This is some helpful text
                                  </span>
                                }
                              >
                                <h3>Child Victims Act or Similar Law</h3>
                              </Tooltip>
                            </dt>
                            <dd className="mt-1 text-sm text-gray-900 sm:col-span-2">
                              {cleanupContent(
                                selectedState.child_victims_act_or_similar_law
                              )}
                            </dd>
                          </div>
                          <div>
                            <dt className="text-primary-blue text-sm font-medium  sm:flex-shrink-0">
                              <Tooltip
                                label={
                                  <span className="flex items-center bg-slate-700 text-white text-sm font-medium py-1 px-2 rounded-sm shadow-md">
                                    This is some helpful text
                                  </span>
                                }
                              >
                                <h4>"Look-Back Window" Open Dates</h4>
                              </Tooltip>
                            </dt>
                            <dd className="mt-1 text-sm text-gray-900 sm:col-span-2">
                              {cleanupContent(
                                selectedState.look_back_window_open_dates
                              )}
                            </dd>
                          </div>
                          <div>
                            <dt className="text-primary-blue text-sm font-medium  sm:flex-shrink-0">
                              <Tooltip
                                label={
                                  <span className="flex items-center bg-slate-700 text-white text-sm font-medium py-1 px-2 rounded-sm shadow-md">
                                    This is some helpful text
                                  </span>
                                }
                              >
                                <h4>
                                  "Look-Back Window" Civil Suit Filing Deadline
                                </h4>
                              </Tooltip>
                            </dt>
                            <dd className="mt-1 text-sm text-gray-900 sm:col-span-2">
                              {cleanupContent(
                                selectedState.look_back_window_civil_suit_filing_deadline
                              )}
                            </dd>
                          </div>
                          <div>
                            <dt className="text-primary-blue text-sm font-medium  sm:flex-shrink-0">
                              <Tooltip
                                label={
                                  <span className="flex items-center bg-slate-700 text-white text-sm font-medium py-1 px-2 rounded-sm shadow-md">
                                    This is some helpful text
                                  </span>
                                }
                              >
                                <h4>Pending SOL Reform Legislation</h4>
                              </Tooltip>
                            </dt>
                            <dd className="mt-1 text-sm text-gray-900 sm:col-span-2">
                              {cleanupContent(
                                selectedState.pending_sql_reform_legislation
                              )}
                            </dd>
                          </div>
                          <div>
                            <dt className="text-primary-blue text-sm font-medium  sm:flex-shrink-0">
                              <Tooltip
                                label={
                                  <span className="flex items-center bg-slate-700 text-white text-sm font-medium py-1 px-2 rounded-sm shadow-md">
                                    This is some helpful text
                                  </span>
                                }
                              >
                                <h4>Pending SOL Reform Legislation Summary</h4>
                              </Tooltip>
                            </dt>
                            <dd className="mt-1 text-sm text-gray-900 sm:col-span-2">
                              {cleanupContent(
                                selectedState.pending_sql_reform_legislation_summary
                              )}
                            </dd>
                          </div>
                          <div>
                            <dt className="text-primary-blue text-sm font-medium  sm:flex-shrink-0">
                              <Tooltip
                                label={
                                  <span className="flex items-center bg-slate-700 text-white text-sm font-medium py-1 px-2 rounded-sm shadow-md">
                                    This is some helpful text
                                  </span>
                                }
                              >
                                <h4>
                                  Current Criminal Statute of Limitations (SOL)
                                </h4>
                              </Tooltip>
                            </dt>
                            <dd className="mt-1 text-sm text-gray-900 sm:col-span-2">
                              {cleanupContent(
                                selectedState.current_criminal_statute_of_limitations_sql
                              )}
                            </dd>
                          </div>
                          <div>
                            <dt className="text-primary-blue text-sm font-medium  sm:flex-shrink-0">
                              <Tooltip
                                label={
                                  <span className="flex items-center bg-slate-700 text-white text-sm font-medium py-1 px-2 rounded-sm shadow-md">
                                    Link to Source/More Information about
                                    Criminal SOLs
                                  </span>
                                }
                              >
                                <h4>
                                  Current Criminal Statute of Limitations (SOL)
                                </h4>
                              </Tooltip>
                            </dt>
                            <dd className="mt-1 text-sm text-gray-900 sm:col-span-2">
                              {cleanupContent(
                                selectedState.more_sources_criminal_sqls
                              )}
                            </dd>
                          </div>
                          <div>
                            <dt className="text-primary-blue text-sm font-medium  sm:flex-shrink-0">
                              <Tooltip
                                label={
                                  <span className="flex items-center bg-slate-700 text-white text-sm font-medium py-1 px-2 rounded-sm shadow-md">
                                    Link to Source/More Information about
                                    Criminal SOLs
                                  </span>
                                }
                              >
                                <h4>
                                  Current Civll Statutes of Limitations (SOL)
                                </h4>
                              </Tooltip>
                            </dt>
                            <dd className="mt-1 text-sm text-gray-900 sm:col-span-2">
                              {cleanupContent(
                                selectedState.current_civll_statutes_of_limitations_sql
                              )}
                            </dd>
                          </div>
                          <div>
                            <dt className="text-primary-blue text-sm font-medium  sm:flex-shrink-0">
                              <Tooltip
                                label={
                                  <span className="flex items-center bg-slate-700 text-white text-sm font-medium py-1 px-2 rounded-sm shadow-md">
                                    Link to Source/More Information about Civil
                                    SOLs
                                  </span>
                                }
                              >
                                <h4>
                                  Current Civll Statutes of Limitations (SOL)
                                </h4>
                              </Tooltip>
                            </dt>
                            <dd className="mt-1 text-sm text-gray-900 sm:col-span-2">
                              {cleanupContent(
                                selectedState.more_sources_criminal_sqls
                              )}
                            </dd>
                          </div>
                        </dl>
                      </div>
                      <div className="mt-4 sm:mt-6 mb-6 sm:mb-8 px-4 sm:flex sm:items-end sm:px-6">
                        <Button className="mr-2" onClick={callToActionCLI}>
                          Learn More
                        </Button>
                      </div>
                    </div>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}

export default SliderOut;
