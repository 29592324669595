import React, { useCallback, useRef } from 'react';
import ReactToPrint, { useReactToPrint } from 'react-to-print';
import { useRecoilState } from 'recoil';
import { selectedInfoState, showPrintView } from '../../atoms';
import { cleanupContent } from '../../utils';
import { Component as Button } from '../Buttton';
import { Component as Tooltip } from '../Tooltip';

const PrintContentViewtoPrint = React.forwardRef((props, ref) => {
  const [selectedState] = useRecoilState(selectedInfoState);

  return (
    // @ts-ignore
    <div className="mt-16 h-4/5 overflow-y-scroll" ref={ref}>
      <div className="container mx-auto">
        <div className="px-4 pt-5 pb-5 sm:px-0 sm:pt-0">
          <div className="space-y-8 sm:space-y-6 sm:px-6 mb-4">
            <div>
              <div className="flex items-center">
                <h3 className="text-xl font-bold text-gray-900 sm:text-2xl">
                  {selectedState.title}
                </h3>
              </div>
              <p className="text-sm text-gray-500">{selectedState.modified}</p>
            </div>
          </div>
          <dl className="space-y-8 sm:space-y-6 sm:px-6">
            <div>
              <dt className="text-primary-blue text-sm font-medium text-gray-500 sm:flex-shrink-0">
                <Tooltip
                  label={
                    <span className="flex items-center bg-slate-700 text-white text-sm font-medium py-1 px-2 rounded-sm shadow-md">
                      This is some helpful text
                    </span>
                  }
                >
                  <h4>Child Victims Act or Similar Law</h4>
                </Tooltip>
              </dt>
              <dd className="mt-1 text-sm text-gray-900 sm:col-span-2">
                {cleanupContent(selectedState.child_victims_act_or_similar_law)}
              </dd>
            </div>
            <div>
              <dt className="text-primary-blue text-sm font-medium text-gray-500 sm:flex-shrink-0">
                <Tooltip
                  label={
                    <span className="flex items-center bg-slate-700 text-white text-sm font-medium py-1 px-2 rounded-sm shadow-md">
                      This is some helpful text
                    </span>
                  }
                >
                  <h4>"Look-Back Window" Open Dates</h4>
                </Tooltip>
              </dt>
              <dd className="mt-1 text-sm text-gray-900 sm:col-span-2">
                {cleanupContent(selectedState.look_back_window_open_dates)}
              </dd>
            </div>
            <div>
              <dt className="text-primary-blue text-sm font-medium text-gray-500 sm:flex-shrink-0">
                <Tooltip
                  label={
                    <span className="flex items-center bg-slate-700 text-white text-sm font-medium py-1 px-2 rounded-sm shadow-md">
                      This is some helpful text
                    </span>
                  }
                >
                  <h4>"Look-Back Window" Civil Suit Filing Deadline</h4>
                </Tooltip>
              </dt>
              <dd className="mt-1 text-sm text-gray-900 sm:col-span-2">
                {cleanupContent(
                  selectedState.look_back_window_civil_suit_filing_deadline
                )}
              </dd>
            </div>
            <div>
              <dt className="text-primary-blue text-sm font-medium text-gray-500 sm:flex-shrink-0">
                <Tooltip
                  label={
                    <span className="flex items-center bg-slate-700 text-white text-sm font-medium py-1 px-2 rounded-sm shadow-md">
                      This is some helpful text
                    </span>
                  }
                >
                  <h4>Pending SOL Reform Legislation</h4>
                </Tooltip>
              </dt>
              <dd className="mt-1 text-sm text-gray-900 sm:col-span-2">
                {cleanupContent(selectedState.pending_sql_reform_legislation)}
              </dd>
            </div>
            <div>
              <dt className="text-primary-blue text-sm font-medium text-gray-500 sm:flex-shrink-0">
                <Tooltip
                  label={
                    <span className="flex items-center bg-slate-700 text-white text-sm font-medium py-1 px-2 rounded-sm shadow-md">
                      This is some helpful text
                    </span>
                  }
                >
                  <h4>Pending SOL Reform Legislation Summary</h4>
                </Tooltip>
              </dt>
              <dd className="mt-1 text-sm text-gray-900 sm:col-span-2">
                {cleanupContent(
                  selectedState.pending_sql_reform_legislation_summary
                )}
              </dd>
            </div>
            <div>
              <dt className="text-primary-blue text-sm font-medium text-gray-500 sm:flex-shrink-0">
                <Tooltip
                  label={
                    <span className="flex items-center bg-slate-700 text-white text-sm font-medium py-1 px-2 rounded-sm shadow-md">
                      This is some helpful text
                    </span>
                  }
                >
                  <h4>Current Criminal Statute of Limitations (SOL)</h4>
                </Tooltip>
              </dt>
              <dd className="mt-1 text-sm text-gray-900 sm:col-span-2">
                {cleanupContent(
                  selectedState.current_criminal_statute_of_limitations_sql
                )}
              </dd>
            </div>
            <div>
              <dt className="text-primary-blue text-sm font-medium text-gray-500 sm:flex-shrink-0">
                <Tooltip
                  label={
                    <span className="flex items-center bg-slate-700 text-white text-sm font-medium py-1 px-2 rounded-sm shadow-md">
                      Link to Source/More Information about Criminal SOLs
                    </span>
                  }
                >
                  <h4>Current Criminal Statute of Limitations (SOL)</h4>
                </Tooltip>
              </dt>
              <dd className="mt-1 text-sm text-gray-900 sm:col-span-2">
                {cleanupContent(selectedState.more_sources_criminal_sqls)}
              </dd>
            </div>
            <div>
              <dt className="text-primary-blue text-sm font-medium text-gray-500 sm:flex-shrink-0">
                <Tooltip
                  label={
                    <span className="flex items-center bg-slate-700 text-white text-sm font-medium py-1 px-2 rounded-sm shadow-md">
                      Link to Source/More Information about Criminal SOLs
                    </span>
                  }
                >
                  <h4>Current Civll Statutes of Limitations (SOL)</h4>
                </Tooltip>
              </dt>
              <dd className="mt-1 text-sm text-gray-900 sm:col-span-2">
                {cleanupContent(
                  selectedState.current_civll_statutes_of_limitations_sql
                )}
              </dd>
            </div>
            <div>
              <dt className="text-primary-blue text-sm font-medium text-gray-500 sm:flex-shrink-0">
                <Tooltip
                  label={
                    <span className="flex items-center bg-slate-700 text-white text-sm font-medium py-1 px-2 rounded-sm shadow-md">
                      Link to Source/More Information about Civil SOLs
                    </span>
                  }
                >
                  <h4>Current Civll Statutes of Limitations (SOL)</h4>
                </Tooltip>
              </dt>
              <dd className="mt-1 text-sm text-gray-900 sm:col-span-2">
                {cleanupContent(selectedState.more_sources_criminal_sqls)}
              </dd>
            </div>
          </dl>
        </div>
      </div>
    </div>
  );
});

const PrintContentView = () => {
  const [isOpenPrintView, setIsOpenPrintView] = useRecoilState(showPrintView);
  const onClose = useCallback(() => {
    setIsOpenPrintView(!isOpenPrintView);
  }, [isOpenPrintView, setIsOpenPrintView]);
  const componentRef = useRef<HTMLDivElement>(null);
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  return (
    <div
      className="fixed top-0 left-0 w-full h-full bg-white z-30"
      style={{
        marginLeft: isOpenPrintView ? 0 : -999999999,
      }}
    >
      <PrintContentViewtoPrint ref={componentRef} />
      {componentRef?.current ? (
        <>
          <ReactToPrint content={() => componentRef.current} />
          <div className="container mx-auto">
            <div className="space-y-8 px-4 sm:space-y-6 sm:px-6">
              <Button className="mr-4" onClick={onClose}>
                Close
              </Button>
              <Button onClick={handlePrint}>Print this out!</Button>
            </div>
          </div>
        </>
      ) : null}
    </div>
  );
};

export default PrintContentView;
