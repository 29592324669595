const isStringContainsLink = (content: string) => {
  return content.includes('http://') || content.includes('https://');
};

const renderAnchorLink = (content: string) => {
  return `<a class="underline text-blue-600 hover:text-blue-800 visited:text-purple-600" href="${content}" target="_blank">${content}</a>`;
};

export const cleanupContent = (content: string | undefined) => {
  if (!content || content === '') {
    return 'N/A';
  }

  let prepareContent: string[] | string = content.split(' ').map((value) => {
    if (isStringContainsLink(value)) {
      return renderAnchorLink(value);
    }
    return value;
  });

  prepareContent = prepareContent.join(' ');

  return <div dangerouslySetInnerHTML={{ __html: prepareContent }} />;
};

export const classNames = (...classes: any) => {
  return classes.filter(Boolean).join(' ');
};
