const statesData = [
  {
    id: 1,
    state_id: '02',
    state: 'Alaska',
    capital: 'Juneau',
    image: 'images/states/Alaska.jpg',
    latitude: 61.385,
    longitude: -152.2683,
  },
  {
    id: 2,
    state_id: '01',
    state: 'Alabama',
    capital: 'Montgomery',
    image: 'images/states/Alabama.jpg',
    latitude: 32.799,
    longitude: -86.8073,
  },
  {
    id: 3,
    state_id: '05',
    state: 'Arkansas',
    capital: 'Little Rock',
    image: 'images/states/Arkansas.jpg',
    latitude: 34.9513,
    longitude: -92.3809,
  },
  {
    id: 4,
    state_id: '04',
    state: 'Arizona',
    capital: 'Phoenix',
    image: 'images/states/Arizona.jpg',
    latitude: 33.7712,
    longitude: -111.3877,
  },
  {
    id: 5,
    state_id: '06',
    state: 'California',
    capital: 'Sacramento',
    image: 'images/states/California.jpg',
    latitude: 36.17,
    longitude: -119.7462,
  },
  {
    id: 6,
    state_id: '08',
    state: 'Colorado',
    capital: 'Denver',
    image: 'images/states/Colorado.jpg',
    latitude: 39.0646,
    longitude: -105.3272,
  },
  {
    id: 7,
    state_id: '09',
    state: 'Connecticut',
    capital: 'Hartford',
    image: 'images/states/Connecticut.jpg',
    latitude: 41.5834,
    longitude: -72.7622,
  },
  {
    id: 8,
    state_id: '10',
    state: 'Delaware',
    capital: 'Dover',
    image: 'images/states/Delaware.jpg',
    latitude: 39.3498,
    longitude: -75.5148,
  },
  {
    id: 9,
    state_id: '12',
    state: 'Florida',
    capital: 'Tallahassee',
    image: 'images/states/Florida.jpg',
    latitude: 27.8333,
    longitude: -81.717,
  },
  {
    id: 10,
    state_id: '13',
    state: 'Georgia',
    capital: 'Atlanta',
    image: 'images/states/Georgia.jpg',
    latitude: 32.9866,
    longitude: -83.6487,
  },
  {
    id: 11,
    state_id: '15',
    state: 'Hawaii',
    capital: 'Honolulu',
    image: 'images/states/Hawaii.jpg',
    latitude: 21.1098,
    longitude: -157.5311,
  },
  {
    id: 12,
    state_id: '19',
    state: 'Iowa',
    capital: 'Des Moines',
    image: 'images/states/Iowa.jpg',
    latitude: 42.0046,
    longitude: -93.214,
  },
  {
    id: 13,
    state_id: '16',
    state: 'Idaho',
    capital: 'Boise',
    image: 'images/states/Idaho.jpg',
    latitude: 44.2394,
    longitude: -114.5103,
  },
  {
    id: 14,
    state_id: '17',
    state: 'Illinois',
    capital: 'Springfield',
    image: 'images/states/Illinois.jpg',
    latitude: 40.3363,
    longitude: -89.0022,
  },
  {
    id: 15,
    state_id: '18',
    state: 'Indiana',
    capital: 'Indianapolis',
    image: 'images/states/Indiana.jpg',
    latitude: 39.8647,
    longitude: -86.2604,
  },
  {
    id: 16,
    state_id: '20',
    state: 'Kansas',
    capital: 'Topeka',
    image: 'images/states/Kansas.jpg',
    latitude: 38.5111,
    longitude: -96.8005,
  },
  {
    id: 17,
    state_id: '21',
    state: 'Kentucky',
    capital: 'Frankfort',
    image: 'images/states/Kentucky.jpg',
    latitude: 37.669,
    longitude: -84.6514,
  },
  {
    id: 18,
    state_id: '22',
    state: 'Louisiana',
    capital: 'Baton Rouge',
    image: 'images/states/Louisiana.jpg',
    latitude: 31.1801,
    longitude: -91.8749,
  },
  {
    id: 19,
    state_id: '25',
    state: 'Massachusetts',
    capital: 'Boston',
    image: 'images/states/Massachusetts.jpg',
    latitude: 42.2373,
    longitude: -71.5314,
  },
  {
    id: 20,
    state_id: '24',
    state: 'Maryland',
    capital: 'Annapolis',
    image: 'images/states/Maryland.jpg',
    latitude: 39.0724,
    longitude: -76.7902,
  },
  {
    id: 21,
    state_id: '23',
    state: 'Maine',
    capital: 'Augusta',
    image: 'images/states/Maine.jpg',
    latitude: 44.6074,
    longitude: -69.3977,
  },
  {
    id: 22,
    state_id: '26',
    state: 'Michigan',
    capital: 'Lansing',
    image: 'images/states/Michigan.jpg',
    latitude: 43.3504,
    longitude: -84.5603,
  },
  {
    id: 23,
    state_id: 'Minnesota',
    state: 'Minnesota',
    capital: 'St. Paul',
    image: 'images/states/Minnesota.jpg',
    latitude: 45.7326,
    longitude: -93.9196,
  },
  {
    id: 24,
    state_id: '29',
    state: 'Missouri',
    capital: 'Jefferson City',
    image: 'images/states/Missouri.jpg',
    latitude: 38.4623,
    longitude: -92.302,
  },
  {
    id: 25,
    state_id: '28',
    state: 'Mississippi',
    capital: 'Jackson',
    image: 'images/states/Mississippi.jpg',
    latitude: 32.7673,
    longitude: -89.6812,
  },
  {
    id: 26,
    state_id: '30',
    state: 'Montana',
    capital: 'Helena',
    image: 'images/states/Montana.jpg',
    latitude: 46.9048,
    longitude: -110.3261,
  },
  {
    id: 27,
    state_id: '37',
    state: 'North Carolina',
    capital: 'Raleigh',
    image: 'images/states/North_Carolina.jpg',
    latitude: 35.6411,
    longitude: -79.8431,
  },
  {
    id: 28,
    state_id: '38',
    state: 'North Dakota',
    capital: 'Bismarck',
    image: 'images/states/North_Dakota.jpg',
    latitude: 47.5362,
    longitude: -99.793,
  },
  {
    id: 29,
    state_id: '31',
    state: 'Nebraska',
    capital: 'Lincoln',
    image: 'images/states/Nebraska.jpg',
    latitude: 41.1289,
    longitude: -98.2883,
  },
  {
    id: 30,
    state_id: '33',
    state: 'New Hampshire',
    capital: 'Concord',
    image: 'images/states/New_Hampshire.jpg',
    latitude: 43.4108,
    longitude: -71.5653,
  },
  {
    id: 31,
    state_id: '34',
    state: 'New Jersey',
    capital: 'Trenton',
    image: 'images/states/New_Jersey.jpg',
    latitude: 40.314,
    longitude: -74.5089,
  },
  {
    id: 32,
    state_id: '35',
    state: 'New Mexico',
    capital: 'Santa Fe',
    image: 'images/states/New_Mexico.jpg',
    latitude: 34.8375,
    longitude: -106.2371,
  },
  {
    id: 33,
    state_id: '32',
    state: 'Nevada',
    capital: 'Carson City',
    image: 'images/states/Nevada.jpg',
    latitude: 38.4199,
    longitude: -117.1219,
  },
  {
    id: 34,
    state_id: '36',
    state: 'New York',
    capital: 'Albany',
    image: 'images/states/New_York.jpg',
    latitude: 42.1497,
    longitude: -74.9384,
  },
  {
    id: 35,
    state_id: '39',
    state: 'Ohio',
    capital: 'Columbus',
    image: 'images/states/Ohio.jpg',
    latitude: 40.3736,
    longitude: -82.7755,
  },
  {
    id: 36,
    state_id: '40',
    state: 'Oklahoma',
    capital: 'Oklahoma City',
    image: 'images/states/Oklahoma.jpg',
    latitude: 35.5376,
    longitude: -96.9247,
  },
  {
    id: 37,
    state_id: '41',
    state: 'Oregon',
    capital: 'Salem',
    image: 'images/states/Oregon.jpg',
    latitude: 44.5672,
    longitude: -122.1269,
  },
  {
    id: 38,
    state_id: '42',
    state: 'Pennsylvania',
    capital: 'Harrisburg',
    image: 'images/states/Pennsylvania.jpg',
    latitude: 40.5773,
    longitude: -77.264,
  },
  {
    id: 39,
    state_id: '44',
    state: 'Rhode Island',
    capital: 'Providence',
    image: 'images/states/Rhode_Island.jpg',
    latitude: 41.6772,
    longitude: -71.5101,
  },
  {
    id: 40,
    state_id: '45',
    state: 'South Carolina',
    capital: 'Columbia',
    image: 'images/states/South_Carolina.jpg',
    latitude: 33.8191,
    longitude: -80.9066,
  },
  {
    id: 41,
    state_id: '46',
    state: 'South Dakota',
    capital: 'Pierre',
    image: 'images/states/South_Dakota.jpg',
    latitude: 44.2853,
    longitude: -99.4632,
  },
  {
    id: 42,
    state_id: '47',
    state: 'Tennessee',
    capital: 'Nashville',
    image: 'images/states/Tennessee.jpg',
    latitude: 35.7449,
    longitude: -86.7489,
  },
  {
    id: 43,
    state_id: '48',
    state: 'Texas',
    capital: 'Austin',
    image: 'images/states/Texas.jpg',
    latitude: 31.106,
    longitude: -97.6475,
  },
  {
    id: 44,
    state_id: '49',
    state: 'Utah',
    capital: 'Salt Lake City',
    image: 'images/states/Utah.jpg',
    latitude: 40.1135,
    longitude: -111.8535,
  },
  {
    id: 45,
    state_id: '51',
    state: 'Virginia',
    capital: 'Richmond',
    image: 'images/states/Virginia.jpg',
    latitude: 37.768,
    longitude: -78.2057,
  },
  {
    id: 46,
    state_id: '50',
    state: 'Vermont',
    capital: 'Montpelier',
    image: 'images/states/Vermont.jpg',
    latitude: 44.0407,
    longitude: -72.7093,
  },
  {
    id: 47,
    state_id: '53',
    state: 'Washington',
    capital: 'Olympia',
    image: 'images/states/Washington.jpg',
    latitude: 47.3917,
    longitude: -121.5708,
  },
  {
    id: 48,
    state_id: '45',
    state: 'South Carolina',
    capital: 'Columbia',
    image: 'images/states/South_Carolina.jpg',
    latitude: 38.8974,
    longitude: -77.0268,
  },
  {
    id: 49,
    state_id: '55',
    state: 'Wisconsin',
    capital: 'Madison',
    image: 'images/states/Wisconsin.jpg',
    latitude: 44.2563,
    longitude: -89.6385,
  },
  {
    id: 50,
    state_id: '54',
    state: 'West Virginia',
    capital: 'Charleston',
    image: 'images/states/West_Virginia.jpg',
    latitude: 38.468,
    longitude: -80.9696,
  },
  {
    id: 51,
    state_id: '56',
    state: 'Wyoming',
    capital: 'Cheyenne',
    image: 'images/states/Wyoming.jpg',
    latitude: 42.7475,
    longitude: -107.2085,
  },
];

const defaultCountry = {
  id: 0,
  state_id: '0',
  state: 'default',
  capital: '',
  image: '',
  latitude: 42.0046,
  longitude: -93.214,
};

export default statesData;
export { statesData, defaultCountry };

export interface IState {
  id: number;
  modified?: string;
  title?: any;
  _embedded?: any;
  state_id: string;
  state: string;
  capital: string;
  image: string;
  latitude: number;
  longitude: number;

  child_victims_act_or_similar_law?: string;
  look_back_window_open_dates?: string;
  look_back_window_civil_suit_filing_deadline?: string;

  pending_sql_reform_legislation?: string;
  pending_sql_reform_legislation_summary?: string;
  current_criminal_statute_of_limitations_sql?: string;
  more_sources_criminal_sqls?: string;

  current_civll_statutes_of_limitations_sql?: string;
  more_sources_civil_sqls?: string;
}

export const convertStateToPath = (state: string) => {
  return state.toLocaleLowerCase().split(' ').join('_');
};

const capitalizedFirstLetterWords = (mySentence: string) => {
  let words = mySentence.split(' ');
  words = words.map((word) => {
    return word[0].toUpperCase() + word.substring(1);
  });

  return words.join(' ');
};

export const getStateFromLocationPath = (): IState => {
  const pathname = window.location.pathname;

  if (pathname === '/') {
    return defaultCountry;
  }

  const states = statesData;
  const name = capitalizedFirstLetterWords(
    pathname.substring(1).split('_').join(' ')
  );

  const matchState = states.find((state): null | IState => {
    if (state.state === name) {
      return state;
    }
    return null;
  });

  if (matchState) {
    return {
      ...matchState,
      state: convertStateToPath(matchState.state),
      title: matchState.state,
    };
  }

  return defaultCountry;
};
