import axios from 'axios';
import { format } from 'date-fns';

import { useEffect } from 'react';
import { Route, Routes } from 'react-router-dom';
import { useRecoilState } from 'recoil';
import { statesInfoState } from './atoms';
import { config } from './Config';
import { convertStateToPath } from './data';
import Main from './Main';

const excludedStatebyNames = ['District of Columbia'];
const App = () => {
  const [_, setStates] = useRecoilState(statesInfoState);

  useEffect(() => {
    (async () => {
      const result = await axios.get(config.mapEntriesUrl);
      if (result && result.data) {
        let formattedData = result.data
          .filter((d: any) => {
            if (excludedStatebyNames.indexOf(d.title.rendered) !== -1) {
              return false;
            }
            return true;
          })
          .map((d: any) => {
            return {
              id: d.id,
              state_id: d.state_id,
              state: convertStateToPath(d.title.rendered),
              title: d.title.rendered,
              capital: d.capital,
              modified: format(new Date(d.modified), 'MMM dd, yyyy'),
              image:
                d._embedded &&
                d._embedded['wp:featuredmedia'] &&
                d._embedded['wp:featuredmedia'].length
                  ? d._embedded['wp:featuredmedia'][0].source_url
                  : null,

              latitude: d.latitude,
              longitude: d.longitude,
              child_victims_act_or_similar_law:
                d.child_victims_act_or_similar_law,
              look_back_window_open_dates: d['lookback-window'],
              look_back_window_civil_suit_filing_deadline:
                d['lookback-window-deadline'],
              pending_sql_reform_legislation: d['pending-legislation'],
              pending_sql_reform_legislation_summary:
                d['pending-legislation-detail'],
              current_criminal_statute_of_limitations_sql:
                d.current_criminal_statute_of_limitations_sql,
              more_sources_criminal_sqls: d.more_sources_criminal_sqls,
              current_civll_statutes_of_limitations_sql:
                d.current_civll_statutes_of_limitations_sql,
              more_sources_civil_sqls: d.more_sources_civil_sqls,
            };
          });
        formattedData = formattedData.sort((a: any, b: any) => {
          if (a.title < b.title) {
            return -1;
          }
          if (a.title > b.title) {
            return 1;
          }
          return 0;
        });
        setStates(formattedData);
      }
    })();
  }, [setStates]);

  return (
    <Routes>
      <Route path="/" element={<Main />}>
        <Route index element={<Main />} />
        <Route path="/:selectedState" element={<Main />} />
      </Route>
    </Routes>
  );
};

export default App;
