import { useNavigate } from 'react-router-dom';
import { useRecoilState, useRecoilValue } from 'recoil';
import { selectedInfoState, statesInfoState } from '../../atoms';
import { IState } from '../../data';
import { classNames } from '../../utils';

function SidebarMenu() {
  let navigate = useNavigate();
  const states = useRecoilValue(statesInfoState);
  const [selectedState, setSelectedState] = useRecoilState(selectedInfoState);

  const chooseCity = (state: IState) => {
    setSelectedState(state);
    navigate(`/${state.state}`);
  };

  return (
    <div className="px-2 pt-4">
      {states.length !== 0 ? (
        <nav
          className="h-full overflow-y-auto scrollbar-thin"
          aria-label="Directory"
        >
          <ul role="list" className="relative z-0 divide-y divide-transparent">
            {states.map((state) => (
              <li key={state.id}>
                <div className="relative flex items-center space-x-3">
                  <div className="flex-1 min-w-0">
                    <a
                      href="#"
                      className={classNames(
                        'focus:outline-none px-2 py-4 block mr-2 rounded-md',
                        state.state_id === selectedState.state_id
                          ? 'text-amber-400 font-sm hover:text-white hover:bg-amber-400'
                          : 'text-white hover:bg-amber-400'
                      )}
                      onClick={() => {
                        chooseCity(state);
                      }}
                    >
                      {state.title}
                    </a>
                  </div>
                </div>
              </li>
            ))}
          </ul>
        </nav>
      ) : (
        <p className="text-white">Fetching States...</p>
      )}
    </div>
  );
}

export default SidebarMenu;
