const states: any = {
  type: 'FeatureCollection',
  features: [
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-80.519, 40.6388],
            [-80.5191, 40.5902],
            [-80.5191, 39.958],
            [-80.5193, 39.7214],
            [-79.4767, 39.7211],
            [-79.4869, 39.2059],
            [-79.2144, 39.3634],
            [-79.0911, 39.4725],
            [-78.9568, 39.4402],
            [-78.7967, 39.6056],
            [-78.6547, 39.5346],
            [-78.4733, 39.5159],
            [-78.397, 39.6165],
            [-78.2666, 39.6189],
            [-78.1719, 39.6957],
            [-77.8786, 39.5634],
            [-77.7359, 39.3913],
            [-77.7195, 39.3213],
            [-77.8283, 39.1325],
            [-78.347, 39.466],
            [-78.3404, 39.3536],
            [-78.4194, 39.2575],
            [-78.4039, 39.1677],
            [-78.6805, 38.9253],
            [-78.788, 38.8851],
            [-78.8693, 38.763],
            [-78.994, 38.8501],
            [-79.2106, 38.4929],
            [-79.3127, 38.412],
            [-79.4998, 38.4977],
            [-79.6491, 38.5915],
            [-79.6897, 38.4315],
            [-79.7889, 38.2687],
            [-79.9163, 38.1864],
            [-80.0091, 37.9907],
            [-80.1622, 37.8751],
            [-80.2961, 37.6917],
            [-80.2589, 37.5955],
            [-80.2998, 37.5083],
            [-80.4756, 37.423],
            [-80.552, 37.4736],
            [-80.9005, 37.315],
            [-81.2251, 37.2349],
            [-81.3627, 37.3381],
            [-81.5613, 37.2066],
            [-81.6782, 37.2015],
            [-81.8535, 37.2877],
            [-81.9966, 37.4767],
            [-81.968, 37.538],
            [-82.1341, 37.5536],
            [-82.3017, 37.6808],
            [-82.3406, 37.786],
            [-82.5022, 37.9331],
            [-82.5494, 38.0686],
            [-82.6366, 38.138],
            [-82.5745, 38.2645],
            [-82.5935, 38.4218],
            [-82.324, 38.4493],
            [-82.2935, 38.5754],
            [-82.1727, 38.6297],
            [-82.2173, 38.7957],
            [-82.1442, 38.8419],
            [-82.0902, 38.9753],
            [-82.006, 39.0294],
            [-81.8445, 38.9288],
            [-81.764, 39.0142],
            [-81.7564, 39.1776],
            [-81.6834, 39.2711],
            [-81.4561, 39.4093],
            [-81.3449, 39.3468],
            [-81.2214, 39.3862],
            [-81.0236, 39.5526],
            [-80.8804, 39.6207],
            [-80.8702, 39.7631],
            [-80.7401, 39.9708],
            [-80.7373, 40.0801],
            [-80.5999, 40.3177],
            [-80.5992, 40.4826],
            [-80.664, 40.5903],
            [-80.519, 40.6388],
          ],
        ],
      },
      properties: { STATE_ID: '54', STATE_NAME: 'West Virginia' },
      id: 54,
    },
    {
      type: 'Feature',
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-82.9862, 24.6106],
              [-82.8999, 24.7178],
              [-82.7667, 24.6679],
              [-82.8491, 24.5768],
              [-82.9862, 24.6106],
            ],
          ],
          [
            [
              [-85.0025, 31.0007],
              [-84.9368, 30.8185],
              [-84.8647, 30.7115],
              [-84.0727, 30.6755],
              [-83.1314, 30.6236],
              [-82.2147, 30.5686],
              [-82.2063, 30.4923],
              [-82.1703, 30.359],
              [-82.0664, 30.3558],
              [-82.0065, 30.5619],
              [-82.0368, 30.7544],
              [-81.9048, 30.8283],
              [-81.7183, 30.7447],
              [-81.56, 30.7117],
              [-81.347, 30.7124],
              [-81.3809, 30.6273],
              [-81.3309, 30.301],
              [-81.2052, 29.8224],
              [-81.1198, 29.5967],
              [-80.9068, 29.1447],
              [-80.6356, 28.7503],
              [-80.523, 28.6082],
              [-80.4694, 28.4531],
              [-80.544, 28.2711],
              [-80.4998, 28.0772],
              [-80.3281, 27.7503],
              [-80.228, 27.463],
              [-80.0391, 27.0298],
              [-79.9754, 26.8002],
              [-79.9806, 26.5954],
              [-80.0523, 25.975],
              [-80.0561, 25.8377],
              [-80.1418, 25.4347],
              [-80.1165, 25.3669],
              [-80.2179, 25.2952],
              [-80.4534, 24.9637],
              [-80.6523, 24.8314],
              [-80.8522, 24.7489],
              [-81.0477, 24.6402],
              [-81.1477, 24.6491],
              [-81.4651, 24.5528],
              [-81.5399, 24.486],
              [-81.7118, 24.4297],
              [-81.9464, 24.3993],
              [-82.0672, 24.5237],
              [-82.1751, 24.4996],
              [-82.1535, 24.6349],
              [-82.0266, 24.6077],
              [-81.8695, 24.6645],
              [-81.4025, 24.8818],
              [-81.2151, 24.8744],
              [-81.1324, 24.8058],
              [-80.9643, 24.8199],
              [-81.0985, 25.0665],
              [-81.1844, 25.125],
              [-81.234, 25.2345],
              [-81.2057, 25.4108],
              [-81.3472, 25.6367],
              [-81.4451, 25.7376],
              [-81.5881, 25.8099],
              [-81.7181, 25.793],
              [-81.8503, 26.0629],
              [-81.9187, 26.352],
              [-81.9781, 26.3997],
              [-82.1125, 26.3779],
              [-82.2365, 26.4773],
              [-82.3219, 26.6651],
              [-82.3383, 26.8033],
              [-82.4998, 27.0439],
              [-82.5647, 27.2056],
              [-82.8154, 27.536],
              [-82.8057, 27.7094],
              [-82.9013, 27.8391],
              [-82.8781, 28.0428],
              [-82.9083, 28.2066],
              [-82.8196, 28.2593],
              [-82.7462, 28.4493],
              [-82.7274, 28.5956],
              [-82.8517, 28.9199],
              [-82.8513, 29.0174],
              [-82.9501, 29.0761],
              [-83.1441, 29.0728],
              [-83.2387, 29.3086],
              [-83.4495, 29.4834],
              [-83.4645, 29.6133],
              [-83.5971, 29.6917],
              [-83.6473, 29.7972],
              [-84.0434, 30.0398],
              [-84.2513, 29.996],
              [-84.2988, 29.8671],
              [-84.4469, 29.8587],
              [-84.7302, 29.66],
              [-85.0554, 29.5359],
              [-85.2348, 29.625],
              [-85.3844, 29.615],
              [-85.4574, 29.7352],
              [-85.459, 29.9066],
              [-85.5876, 29.9625],
              [-85.9472, 30.1914],
              [-86.1848, 30.278],
              [-86.3975, 30.3254],
              [-86.6459, 30.3462],
              [-86.8819, 30.3246],
              [-87.5183, 30.2295],
              [-87.5, 30.329],
              [-87.3712, 30.4305],
              [-87.4444, 30.5149],
              [-87.4064, 30.6744],
              [-87.5326, 30.7432],
              [-87.6262, 30.8467],
              [-87.5988, 30.9975],
              [-86.5202, 30.9933],
              [-85.546, 30.9964],
              [-85.0025, 31.0007],
            ],
          ],
        ],
      },
      properties: { STATE_ID: '12', STATE_NAME: 'Florida' },
      id: 12,
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-90.6428, 42.5085],
            [-90.0737, 42.5083],
            [-88.7693, 42.4919],
            [-87.8032, 42.4926],
            [-87.0199, 42.4935],
            [-87.2078, 41.761],
            [-87.5237, 41.7599],
            [-87.5263, 40.492],
            [-87.5316, 39.3479],
            [-87.5857, 39.2004],
            [-87.6587, 39.136],
            [-87.5134, 38.956],
            [-87.4959, 38.7854],
            [-87.7562, 38.4625],
            [-87.8335, 38.3196],
            [-87.985, 38.228],
            [-88.037, 37.9563],
            [-88.095, 37.8937],
            [-88.028, 37.7992],
            [-88.1572, 37.6333],
            [-88.082, 37.4728],
            [-88.2792, 37.4533],
            [-88.477, 37.3857],
            [-88.5158, 37.2841],
            [-88.4252, 37.1538],
            [-88.5232, 37.0658],
            [-88.9314, 37.2274],
            [-89.0299, 37.2111],
            [-89.1738, 37.0663],
            [-89.1329, 36.9821],
            [-89.2921, 36.9922],
            [-89.3796, 37.0407],
            [-89.5185, 37.2863],
            [-89.4209, 37.3939],
            [-89.5165, 37.5356],
            [-89.512, 37.6855],
            [-89.6679, 37.7586],
            [-89.7961, 37.8595],
            [-89.8985, 37.8709],
            [-90.0595, 38.0156],
            [-90.2426, 38.1121],
            [-90.3532, 38.2129],
            [-90.3708, 38.3336],
            [-90.1794, 38.6267],
            [-90.213, 38.712],
            [-90.1133, 38.8493],
            [-90.2502, 38.9193],
            [-90.4717, 38.9592],
            [-90.5776, 38.8684],
            [-90.663, 38.9263],
            [-90.727, 39.2512],
            [-91.0383, 39.4484],
            [-91.3678, 39.729],
            [-91.4338, 39.8419],
            [-91.4194, 39.9277],
            [-91.495, 40.0369],
            [-91.5065, 40.2363],
            [-91.4194, 40.3783],
            [-91.3757, 40.3919],
            [-91.3487, 40.6097],
            [-91.1239, 40.6692],
            [-91.0901, 40.8246],
            [-90.9629, 40.925],
            [-90.9463, 41.0945],
            [-91.1142, 41.25],
            [-91.0483, 41.4098],
            [-90.8576, 41.4528],
            [-90.6558, 41.4621],
            [-90.3432, 41.5878],
            [-90.3156, 41.7344],
            [-90.188, 41.8032],
            [-90.1412, 42.0089],
            [-90.2095, 42.1527],
            [-90.3911, 42.2255],
            [-90.4779, 42.3841],
            [-90.6428, 42.5085],
          ],
        ],
      },
      properties: { STATE_ID: '17', STATE_NAME: 'Illinois' },
      id: 17,
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-96.7802, 46.877],
            [-96.834, 47.0098],
            [-96.8548, 47.6069],
            [-97.0549, 47.9466],
            [-97.1498, 48.1835],
            [-97.1478, 48.614],
            [-97.1014, 48.6822],
            [-97.2342, 48.9483],
            [-97.2287, 49.0006],
            [-95.748, 48.9995],
            [-95.1537, 48.9989],
            [-95.1533, 49.3844],
            [-94.9574, 49.3701],
            [-94.8256, 49.2944],
            [-94.6843, 48.884],
            [-94.6944, 48.7822],
            [-94.5921, 48.7191],
            [-94.2645, 48.6989],
            [-94.2444, 48.6534],
            [-93.844, 48.6294],
            [-93.7944, 48.516],
            [-93.4694, 48.5452],
            [-93.3475, 48.6266],
            [-92.985, 48.6237],
            [-92.7282, 48.5393],
            [-92.656, 48.4367],
            [-92.5073, 48.4479],
            [-92.37, 48.2201],
            [-92.289, 48.343],
            [-92.0552, 48.3592],
            [-92.0066, 48.2654],
            [-91.7152, 48.1993],
            [-91.712, 48.1147],
            [-91.4296, 48.0486],
            [-91.2501, 48.0841],
            [-91.0827, 48.1808],
            [-90.8856, 48.2457],
            [-90.7616, 48.0983],
            [-90.3823, 48.0932],
            [-90.1326, 48.1118],
            [-89.8991, 47.9881],
            [-89.8205, 48.0147],
            [-89.4834, 48.0137],
            [-89.9571, 47.2911],
            [-90.6547, 47.3098],
            [-91.5002, 46.9133],
            [-92.0203, 46.704],
            [-92.0895, 46.7492],
            [-92.2916, 46.638],
            [-92.294, 46.0744],
            [-92.3504, 46.0164],
            [-92.6399, 45.9335],
            [-92.8692, 45.7176],
            [-92.885, 45.5788],
            [-92.7734, 45.5682],
            [-92.6466, 45.4418],
            [-92.762, 45.2882],
            [-92.7506, 44.9373],
            [-92.8081, 44.7509],
            [-92.4937, 44.566],
            [-92.3476, 44.5572],
            [-92.2211, 44.4404],
            [-91.9282, 44.3355],
            [-91.8752, 44.2006],
            [-91.5826, 44.0274],
            [-91.4323, 43.9967],
            [-91.2441, 43.7747],
            [-91.2651, 43.61],
            [-91.2177, 43.5005],
            [-92.0346, 43.5007],
            [-93.0724, 43.4996],
            [-94.2771, 43.5001],
            [-95.7907, 43.4999],
            [-96.4533, 43.5004],
            [-96.453, 44.1379],
            [-96.4531, 45.3008],
            [-96.4891, 45.3571],
            [-96.6806, 45.4105],
            [-96.8568, 45.6054],
            [-96.5831, 45.82],
            [-96.5637, 45.9352],
            [-96.6017, 46.3312],
            [-96.7181, 46.4419],
            [-96.7995, 46.6752],
            [-96.7802, 46.877],
          ],
        ],
      },
      properties: { STATE_ID: '27', STATE_NAME: 'Minnesota' },
      id: 27,
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-77.7195, 39.3213],
            [-77.7359, 39.3913],
            [-77.8786, 39.5634],
            [-78.1719, 39.6957],
            [-78.2666, 39.6189],
            [-78.397, 39.6165],
            [-78.4733, 39.5159],
            [-78.6547, 39.5346],
            [-78.7967, 39.6056],
            [-78.9568, 39.4402],
            [-79.0911, 39.4725],
            [-79.2144, 39.3634],
            [-79.4869, 39.2059],
            [-79.4767, 39.7211],
            [-78.0958, 39.7221],
            [-76.9909, 39.7198],
            [-75.7886, 39.7222],
            [-75.743, 39.084],
            [-75.6937, 38.4601],
            [-74.9863, 38.4516],
            [-74.9996, 38.3717],
            [-75.1664, 38.0278],
            [-75.6244, 37.9942],
            [-75.8012, 37.9122],
            [-75.9527, 37.9068],
            [-76.052, 37.9536],
            [-76.2334, 37.8876],
            [-76.5166, 38.0268],
            [-76.6104, 38.1485],
            [-76.8791, 38.1741],
            [-77.0307, 38.3116],
            [-77.2352, 38.3325],
            [-77.3185, 38.4744],
            [-77.0963, 38.7004],
            [-77.0774, 38.7134],
            [-77.0742, 38.7128],
            [-77.0742, 38.712],
            [-77.0436, 38.7191],
            [-77.039, 38.7916],
            [-76.9096, 38.8927],
            [-77.0409, 38.9958],
            [-77.1198, 38.9343],
            [-77.1398, 38.9576],
            [-77.1574, 38.9659],
            [-77.1785, 38.9681],
            [-77.1834, 38.9684],
            [-77.1958, 38.9675],
            [-77.2987, 39.0485],
            [-77.2987, 39.0486],
            [-77.4985, 39.1137],
            [-77.4807, 39.1884],
            [-77.475, 39.2057],
            [-77.4751, 39.2055],
            [-77.475, 39.2057],
            [-77.5683, 39.3065],
            [-77.7195, 39.3213],
          ],
        ],
      },
      properties: { STATE_ID: '24', STATE_NAME: 'Maryland' },
      id: 24,
    },
    {
      type: 'Feature',
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-71.6726, 41.1343],
              [-71.635, 41.2637],
              [-71.4922, 41.2409],
              [-71.5282, 41.1031],
              [-71.6726, 41.1343],
            ],
          ],
          [
            [
              [-71.7992, 42.008],
              [-71.3814, 42.0188],
              [-71.3285, 41.7807],
              [-71.1326, 41.6604],
              [-71.0886, 41.4313],
              [-71.4948, 41.3074],
              [-71.7915, 41.2722],
              [-71.791, 41.1841],
              [-71.9073, 41.3045],
              [-71.7977, 41.4167],
              [-71.7992, 42.008],
            ],
          ],
        ],
      },
      properties: { STATE_ID: '44', STATE_NAME: 'Rhode Island' },
      id: 44,
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-116.9161, 45.9954],
            [-116.9228, 46.1608],
            [-117.0398, 46.4259],
            [-117.0398, 47.4598],
            [-117.0421, 48.033],
            [-117.0323, 48.9992],
            [-116.0491, 49.0008],
            [-116.0491, 47.9768],
            [-115.8246, 47.7524],
            [-115.7227, 47.6949],
            [-115.7545, 47.5525],
            [-115.5285, 47.2992],
            [-115.3235, 47.2563],
            [-114.9223, 46.8286],
            [-114.6209, 46.7073],
            [-114.6131, 46.6385],
            [-114.3208, 46.6471],
            [-114.3719, 46.4425],
            [-114.4708, 46.2654],
            [-114.4452, 46.1679],
            [-114.4934, 46.0526],
            [-114.3872, 45.8885],
            [-114.5663, 45.7727],
            [-114.472, 45.5652],
            [-114.33, 45.4597],
            [-114.0137, 45.6583],
            [-113.8064, 45.6016],
            [-113.8342, 45.5231],
            [-113.7387, 45.3294],
            [-113.5767, 45.1312],
            [-113.452, 45.0592],
            [-113.4551, 44.8654],
            [-113.341, 44.7846],
            [-113.2469, 44.823],
            [-113.1016, 44.7295],
            [-113.0019, 44.4498],
            [-112.8856, 44.3963],
            [-112.7802, 44.4822],
            [-112.4734, 44.4794],
            [-112.2883, 44.5681],
            [-112.0963, 44.5231],
            [-111.5923, 44.5611],
            [-111.4685, 44.6787],
            [-111.338, 44.7329],
            [-111.2322, 44.5852],
            [-111.0524, 44.4784],
            [-111.044, 43.2334],
            [-111.0467, 42.0017],
            [-112.1539, 41.9972],
            [-112.7098, 42.0001],
            [-113.9016, 41.9885],
            [-114.0415, 41.9939],
            [-115.0763, 41.9961],
            [-116.0471, 41.9963],
            [-117.0262, 42.0002],
            [-117.0262, 42.8355],
            [-117.0266, 43.8083],
            [-116.9617, 43.918],
            [-116.8958, 44.1713],
            [-117.2227, 44.2976],
            [-117.2258, 44.4772],
            [-117.1442, 44.5457],
            [-117.0383, 44.7482],
            [-116.9347, 44.7839],
            [-116.832, 44.933],
            [-116.8479, 45.0227],
            [-116.7296, 45.1421],
            [-116.6736, 45.3218],
            [-116.4633, 45.6148],
            [-116.5933, 45.7787],
            [-116.7822, 45.8251],
            [-116.9161, 45.9954],
          ],
        ],
      },
      properties: { STATE_ID: '16', STATE_NAME: 'Idaho' },
      id: 16,
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-71.5522, 44.6279],
            [-71.6304, 44.7533],
            [-71.4948, 44.9059],
            [-71.501, 45.0134],
            [-71.5011, 45.0134],
            [-71.501, 45.0134],
            [-71.3974, 45.2033],
            [-71.2962, 45.2991],
            [-71.18, 45.2401],
            [-71.0839, 45.3055],
            [-71.034, 44.7049],
            [-71.0068, 44.2157],
            [-70.9667, 43.3432],
            [-70.8183, 43.2384],
            [-70.8276, 43.1281],
            [-70.5751, 42.9171],
            [-70.735, 42.8747],
            [-70.9296, 42.885],
            [-71.1328, 42.8215],
            [-71.2946, 42.697],
            [-72.4584, 42.7269],
            [-72.5415, 42.8071],
            [-72.5318, 42.9551],
            [-72.4456, 43.0115],
            [-72.4395, 43.2441],
            [-72.3746, 43.5785],
            [-72.1834, 43.8105],
            [-72.117, 43.9926],
            [-72.034, 44.0819],
            [-72.0679, 44.2734],
            [-71.8725, 44.3367],
            [-71.6002, 44.4858],
            [-71.5522, 44.6279],
          ],
        ],
      },
      properties: { STATE_ID: '33', STATE_NAME: 'New Hampshire' },
      id: 33,
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-81.8485, 36.3389],
            [-81.7223, 36.3523],
            [-81.696, 36.4916],
            [-81.6775, 36.5881],
            [-80.838, 36.5591],
            [-80.2949, 36.544],
            [-79.5531, 36.5409],
            [-78.5116, 36.541],
            [-77.4492, 36.5448],
            [-76.7694, 36.5508],
            [-75.7975, 36.5509],
            [-75.7496, 36.3536],
            [-75.6532, 36.1252],
            [-75.4376, 35.747],
            [-75.4004, 35.5935],
            [-75.4593, 35.2465],
            [-75.8077, 35.1159],
            [-76.0244, 35.0054],
            [-76.2496, 34.8346],
            [-76.4442, 34.6332],
            [-76.492, 34.5465],
            [-76.6147, 34.6183],
            [-76.8189, 34.6433],
            [-77.1247, 34.5832],
            [-77.5162, 34.3824],
            [-77.6911, 34.2457],
            [-77.7856, 34.1252],
            [-77.899, 33.8485],
            [-77.9641, 33.7529],
            [-78.0666, 33.848],
            [-78.2623, 33.8632],
            [-78.4993, 33.8128],
            [-79.2015, 34.4086],
            [-79.6753, 34.8047],
            [-80.7975, 34.8197],
            [-80.782, 34.9358],
            [-80.935, 35.1074],
            [-81.0707, 35.1513],
            [-82.2749, 35.2001],
            [-82.4596, 35.1781],
            [-83.1086, 35.0007],
            [-83.62, 34.9867],
            [-84.3218, 34.9884],
            [-84.2903, 35.2252],
            [-84.0934, 35.2489],
            [-84.0234, 35.2963],
            [-84.0215, 35.4042],
            [-83.8807, 35.5185],
            [-83.6967, 35.5701],
            [-83.4985, 35.5629],
            [-83.1596, 35.7647],
            [-82.9956, 35.7731],
            [-82.8982, 35.9452],
            [-82.6372, 36.0658],
            [-82.55, 35.9638],
            [-82.4096, 36.0832],
            [-82.2221, 36.157],
            [-82.0338, 36.1205],
            [-81.8485, 36.3389],
          ],
        ],
      },
      properties: { STATE_ID: '37', STATE_NAME: 'North Carolina' },
      id: 37,
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-73.3431, 45.0108],
            [-72.6735, 45.0155],
            [-72.2709, 45.0042],
            [-71.5011, 45.0134],
            [-71.501, 45.0134],
            [-71.4948, 44.9059],
            [-71.6304, 44.7533],
            [-71.5522, 44.6279],
            [-71.6002, 44.4858],
            [-71.8725, 44.3367],
            [-72.0679, 44.2734],
            [-72.034, 44.0819],
            [-72.117, 43.9926],
            [-72.1834, 43.8105],
            [-72.3746, 43.5785],
            [-72.4395, 43.2441],
            [-72.4456, 43.0115],
            [-72.5318, 42.9551],
            [-72.5415, 42.8071],
            [-72.4584, 42.7269],
            [-73.2648, 42.7458],
            [-73.2787, 42.8334],
            [-73.249, 43.554],
            [-73.405, 43.6898],
            [-73.3513, 43.7698],
            [-73.4379, 44.0451],
            [-73.3889, 44.1926],
            [-73.3109, 44.2743],
            [-73.3067, 44.5003],
            [-73.3898, 44.6172],
            [-73.3354, 44.8046],
            [-73.3431, 45.0108],
          ],
        ],
      },
      properties: { STATE_ID: '50', STATE_NAME: 'Vermont' },
      id: 50,
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-73.4873, 42.0495],
            [-73.0441, 42.0395],
            [-71.8007, 42.0235],
            [-71.7992, 42.008],
            [-71.7977, 41.4167],
            [-71.9073, 41.3045],
            [-72.0818, 41.2622],
            [-72.9995, 41.0871],
            [-73.6129, 40.9509],
            [-73.7278, 41.1007],
            [-73.4827, 41.2128],
            [-73.551, 41.2954],
            [-73.4873, 42.0495],
          ],
        ],
      },
      properties: { STATE_ID: '09', STATE_NAME: 'Connecticut' },
      id: 9,
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-75.7886, 39.7222],
            [-75.5802, 39.8389],
            [-75.4151, 39.8019],
            [-75.5584, 39.6303],
            [-75.5128, 39.5783],
            [-75.5605, 39.4556],
            [-75.321, 39.2514],
            [-75.1685, 39.0562],
            [-75.0151, 38.7887],
            [-74.9863, 38.4516],
            [-75.6937, 38.4601],
            [-75.743, 39.084],
            [-75.7886, 39.7222],
          ],
        ],
      },
      properties: { STATE_ID: '10', STATE_NAME: 'Delaware' },
      id: 10,
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-103.0023, 37.0001],
            [-103.0024, 36.5005],
            [-103.0417, 36.5004],
            [-103.0419, 35.5356],
            [-103.0431, 34.6718],
            [-103.0436, 34.0036],
            [-103.0647, 32.9953],
            [-103.0645, 32.0004],
            [-104.3291, 32.0001],
            [-105.2458, 32.0002],
            [-106.6185, 32.0005],
            [-106.6359, 31.8662],
            [-106.5282, 31.7831],
            [-107.1892, 31.7836],
            [-108.2085, 31.7837],
            [-108.2087, 31.3334],
            [-109.05, 31.3325],
            [-109.0486, 32.1718],
            [-109.0477, 32.7934],
            [-109.0467, 33.7612],
            [-109.046, 34.5901],
            [-109.0469, 35.3795],
            [-109.0461, 36.0515],
            [-109.0452, 36.999],
            [-107.858, 36.9997],
            [-106.8772, 37.0001],
            [-106.8698, 36.9925],
            [-105.801, 36.9956],
            [-105.0292, 36.9927],
            [-104.3388, 36.9935],
            [-103.0023, 37.0001],
          ],
        ],
      },
      properties: { STATE_ID: '35', STATE_NAME: 'New Mexico' },
      id: 35,
    },
    {
      type: 'Feature',
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-119.6347, 33.2654],
              [-119.5925, 33.3258],
              [-119.4259, 33.2988],
              [-119.3759, 33.1922],
              [-119.5102, 33.1674],
              [-119.6347, 33.2654],
            ],
          ],
          [
            [
              [-118.6748, 33.0121],
              [-118.5421, 33.0738],
              [-118.4489, 32.9618],
              [-118.2973, 32.8451],
              [-118.3238, 32.7735],
              [-118.4365, 32.75],
              [-118.5382, 32.8128],
              [-118.6748, 33.0121],
            ],
          ],
          [
            [
              [-118.6617, 33.4563],
              [-118.5294, 33.5312],
              [-118.3594, 33.4649],
              [-118.2627, 33.3704],
              [-118.328, 33.2481],
              [-118.518, 33.2966],
              [-118.5534, 33.3802],
              [-118.6617, 33.4563],
            ],
          ],
          [
            [
              [-119.4681, 34.0631],
              [-119.3425, 34.069],
              [-119.3805, 33.9536],
              [-119.9503, 33.8944],
              [-120.1041, 33.8424],
              [-120.2211, 33.8842],
              [-120.299, 33.9685],
              [-120.4668, 33.9761],
              [-120.5738, 34.076],
              [-120.561, 34.1407],
              [-120.362, 34.1488],
              [-120.2528, 34.058],
              [-120.0195, 34.0877],
              [-119.9288, 34.1299],
              [-119.6549, 34.0712],
              [-119.5655, 34.1088],
              [-119.4681, 34.0631],
            ],
          ],
          [
            [
              [-123.17, 37.7559],
              [-123.1183, 37.8231],
              [-122.9874, 37.7639],
              [-123.0009, 37.6399],
              [-123.17, 37.7559],
            ],
          ],
          [
            [
              [-124.3288, 41.9983],
              [-123.3455, 41.9993],
              [-122.3785, 42.0095],
              [-121.0221, 41.9933],
              [-119.9992, 41.9949],
              [-119.9999, 40.9999],
              [-119.9974, 40.0392],
              [-120.0064, 39.2704],
              [-120.001, 38.9996],
              [-119.126, 38.3932],
              [-118.3726, 37.856],
              [-117.8759, 37.4973],
              [-117.0009, 36.8477],
              [-116.2508, 36.2769],
              [-115.4502, 35.6537],
              [-114.6335, 35.0019],
              [-114.6352, 34.875],
              [-114.4716, 34.713],
              [-114.4357, 34.5938],
              [-114.3408, 34.4515],
              [-114.1383, 34.3032],
              [-114.2297, 34.1869],
              [-114.4117, 34.11],
              [-114.5349, 33.9257],
              [-114.4965, 33.6969],
              [-114.5242, 33.5531],
              [-114.7253, 33.4023],
              [-114.6745, 33.2556],
              [-114.7079, 33.0974],
              [-114.6708, 33.038],
              [-114.5201, 33.03],
              [-114.4629, 32.9079],
              [-114.5269, 32.7571],
              [-114.7196, 32.7188],
              [-115.5021, 32.6645],
              [-116.3579, 32.5983],
              [-117.2049, 32.5288],
              [-117.2233, 32.6212],
              [-117.3209, 32.6898],
              [-117.3233, 32.9031],
              [-117.3759, 33.0752],
              [-117.521, 33.2689],
              [-117.8164, 33.4909],
              [-118.0965, 33.6536],
              [-118.1259, 33.6972],
              [-118.3454, 33.6634],
              [-118.467, 33.7255],
              [-118.444, 33.8391],
              [-118.5574, 33.9877],
              [-118.874, 33.9833],
              [-119.218, 34.0625],
              [-119.3359, 34.2375],
              [-119.5848, 34.3617],
              [-119.7076, 34.3373],
              [-119.8985, 34.3604],
              [-120.0448, 34.4124],
              [-120.2871, 34.4176],
              [-120.451, 34.3912],
              [-120.5594, 34.4885],
              [-120.7128, 34.5683],
              [-120.6647, 34.6963],
              [-120.6733, 34.8386],
              [-120.733, 34.8926],
              [-120.6896, 35.0988],
              [-120.8473, 35.1379],
              [-120.9603, 35.2436],
              [-120.9333, 35.3927],
              [-121.0416, 35.42],
              [-121.2015, 35.5837],
              [-121.3235, 35.625],
              [-121.3997, 35.7653],
              [-121.6482, 35.9986],
              [-121.7707, 36.162],
              [-121.9591, 36.2871],
              [-122.0427, 36.5966],
              [-122.0011, 36.6477],
              [-122.0764, 36.9102],
              [-122.3761, 37.0656],
              [-122.4641, 37.1778],
              [-122.4659, 37.3412],
              [-122.575, 37.4999],
              [-122.5882, 37.7894],
              [-122.7525, 37.8577],
              [-122.8893, 37.9741],
              [-123.0839, 37.9834],
              [-123.0148, 38.1491],
              [-123.2202, 38.4419],
              [-123.3609, 38.5102],
              [-123.5106, 38.6809],
              [-123.7847, 38.8937],
              [-123.762, 39.0411],
              [-123.8931, 39.3375],
              [-123.8346, 39.5492],
              [-123.8572, 39.6747],
              [-123.9386, 39.8149],
              [-124.1566, 40.0659],
              [-124.3928, 40.2098],
              [-124.4786, 40.4221],
              [-124.4477, 40.5297],
              [-124.2326, 40.8693],
              [-124.1827, 41.0013],
              [-124.2474, 41.0922],
              [-124.1607, 41.2893],
              [-124.1365, 41.4643],
              [-124.2117, 41.6812],
              [-124.4389, 41.8245],
              [-124.285, 41.8595],
              [-124.3288, 41.9983],
            ],
          ],
        ],
      },
      properties: { STATE_ID: '06', STATE_NAME: 'California' },
      id: 6,
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-74.6949, 41.3574],
            [-73.9027, 40.9973],
            [-73.9298, 40.8887],
            [-74.0557, 40.6518],
            [-74.2023, 40.6309],
            [-74.2281, 40.4774],
            [-73.9499, 40.5255],
            [-73.8867, 40.4898],
            [-73.8982, 40.2743],
            [-73.9663, 40.1086],
            [-74.0382, 39.7501],
            [-74.2659, 39.4224],
            [-74.3625, 39.3258],
            [-74.4616, 39.301],
            [-74.6443, 39.1148],
            [-74.7284, 38.9742],
            [-75.0151, 38.7887],
            [-75.1685, 39.0562],
            [-75.321, 39.2514],
            [-75.5605, 39.4556],
            [-75.5128, 39.5783],
            [-75.5584, 39.6303],
            [-75.4151, 39.8019],
            [-75.1373, 39.89],
            [-75.0756, 39.978],
            [-74.8155, 40.1277],
            [-74.7712, 40.2149],
            [-75.0586, 40.4182],
            [-75.0675, 40.54],
            [-75.1941, 40.5761],
            [-75.1695, 40.7785],
            [-75.0515, 40.8641],
            [-75.1311, 40.9905],
            [-75.0268, 41.0397],
            [-74.8821, 41.1808],
            [-74.8301, 41.2872],
            [-74.6949, 41.3574],
          ],
        ],
      },
      properties: { STATE_ID: '34', STATE_NAME: 'New Jersey' },
      id: 34,
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-89.9571, 47.2911],
            [-90.4184, 46.5661],
            [-90.2162, 46.5013],
            [-90.1206, 46.337],
            [-89.0916, 46.1384],
            [-88.7821, 46.0162],
            [-88.6165, 45.9877],
            [-88.5155, 46.0201],
            [-88.1488, 45.9376],
            [-88.0714, 45.7799],
            [-87.8798, 45.7548],
            [-87.7747, 45.602],
            [-87.8553, 45.4414],
            [-87.8504, 45.3475],
            [-87.6481, 45.3394],
            [-87.7352, 45.1771],
            [-87.6612, 45.1083],
            [-87.4428, 45.0765],
            [-87.4057, 45.202],
            [-87.1712, 45.3525],
            [-87.1011, 45.4442],
            [-86.7565, 45.4436],
            [-86.2495, 45.2361],
            [-86.4999, 45.0808],
            [-86.6863, 44.881],
            [-87.0132, 44.1319],
            [-87.1141, 43.7341],
            [-87.1472, 43.3799],
            [-87.0199, 42.4935],
            [-87.8032, 42.4926],
            [-88.7693, 42.4919],
            [-90.0737, 42.5083],
            [-90.6428, 42.5085],
            [-90.7202, 42.6408],
            [-90.9763, 42.696],
            [-91.0638, 42.7506],
            [-91.1435, 42.9047],
            [-91.1753, 43.1347],
            [-91.0579, 43.254],
            [-91.2018, 43.3491],
            [-91.2177, 43.5005],
            [-91.2651, 43.61],
            [-91.2441, 43.7747],
            [-91.4323, 43.9967],
            [-91.5826, 44.0274],
            [-91.8752, 44.2006],
            [-91.9282, 44.3355],
            [-92.2211, 44.4404],
            [-92.3476, 44.5572],
            [-92.4937, 44.566],
            [-92.8081, 44.7509],
            [-92.7506, 44.9373],
            [-92.762, 45.2882],
            [-92.6466, 45.4418],
            [-92.7734, 45.5682],
            [-92.885, 45.5788],
            [-92.8692, 45.7176],
            [-92.6399, 45.9335],
            [-92.3504, 46.0164],
            [-92.294, 46.0744],
            [-92.2916, 46.638],
            [-92.0895, 46.7492],
            [-92.0203, 46.704],
            [-91.5002, 46.9133],
            [-90.6547, 47.3098],
            [-89.9571, 47.2911],
          ],
        ],
      },
      properties: { STATE_ID: '55', STATE_NAME: 'Wisconsin' },
      id: 55,
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-124.1595, 46.2611],
            [-123.8719, 46.2349],
            [-123.48, 46.2692],
            [-123.3714, 46.1464],
            [-123.2656, 46.1505],
            [-123.1159, 46.1853],
            [-122.9041, 46.0837],
            [-122.814, 45.961],
            [-122.7638, 45.6571],
            [-122.4387, 45.5636],
            [-122.249, 45.5477],
            [-122.1017, 45.5832],
            [-121.8112, 45.7067],
            [-121.5329, 45.7265],
            [-121.0849, 45.6479],
            [-120.944, 45.6564],
            [-120.6351, 45.7457],
            [-120.5057, 45.7],
            [-120.2108, 45.7259],
            [-119.9658, 45.8244],
            [-119.67, 45.8569],
            [-119.6236, 45.9056],
            [-119.1263, 45.9328],
            [-118.9873, 45.9999],
            [-117.6813, 45.9998],
            [-116.9161, 45.9954],
            [-116.7822, 45.8251],
            [-116.5933, 45.7787],
            [-116.4633, 45.6148],
            [-116.6736, 45.3218],
            [-116.7296, 45.1421],
            [-116.8479, 45.0227],
            [-116.832, 44.933],
            [-116.9347, 44.7839],
            [-117.0383, 44.7482],
            [-117.1442, 44.5457],
            [-117.2258, 44.4772],
            [-117.2227, 44.2976],
            [-116.8958, 44.1713],
            [-116.9617, 43.918],
            [-117.0266, 43.8083],
            [-117.0262, 42.8355],
            [-117.0262, 42.0002],
            [-118.6746, 41.9922],
            [-119.9992, 41.9949],
            [-121.0221, 41.9933],
            [-122.3785, 42.0095],
            [-123.3455, 41.9993],
            [-124.3288, 41.9983],
            [-124.4163, 42.0748],
            [-124.5008, 42.3766],
            [-124.5672, 42.4778],
            [-124.4736, 42.6147],
            [-124.7003, 42.784],
            [-124.5724, 42.9225],
            [-124.4652, 43.2003],
            [-124.477, 43.3294],
            [-124.3803, 43.4029],
            [-124.303, 43.5681],
            [-124.2302, 43.8637],
            [-124.1635, 44.3748],
            [-124.1478, 44.7736],
            [-124.0501, 45.1407],
            [-124.077, 45.3419],
            [-124.0108, 45.7083],
            [-124.0924, 45.9343],
            [-124.0081, 46.0099],
            [-124.0673, 46.1838],
            [-124.1595, 46.2611],
          ],
        ],
      },
      properties: { STATE_ID: '41', STATE_NAME: 'Oregon' },
      id: 41,
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-104.0529, 42.147],
            [-104.053, 43.0006],
            [-102.8679, 43],
            [-101.633, 42.9963],
            [-100.8875, 42.998],
            [-99.8406, 42.9979],
            [-98.4985, 42.9986],
            [-98.4674, 42.9476],
            [-97.9472, 42.7707],
            [-97.8755, 42.8587],
            [-97.6865, 42.8422],
            [-97.2207, 42.8468],
            [-97.1303, 42.7715],
            [-96.8062, 42.7041],
            [-96.6035, 42.5045],
            [-96.4455, 42.4906],
            [-96.408, 42.3374],
            [-96.3238, 42.2299],
            [-96.3457, 42.1633],
            [-96.2334, 42.0397],
            [-96.2338, 42.0398],
            [-96.234, 42.0399],
            [-96.2348, 42.0401],
            [-96.234, 42.0399],
            [-96.2338, 42.0398],
            [-96.2334, 42.0397],
            [-96.0648, 41.7944],
            [-96.121, 41.677],
            [-96.0919, 41.5341],
            [-95.9228, 41.4573],
            [-95.9517, 41.3375],
            [-95.8121, 40.8842],
            [-95.8832, 40.7176],
            [-95.7656, 40.5852],
            [-95.6104, 40.314],
            [-95.4787, 40.2437],
            [-95.3987, 40.1264],
            [-95.4216, 40.0577],
            [-95.3083, 40],
            [-96.6204, 40.001],
            [-97.4634, 40.002],
            [-98.5003, 40.0022],
            [-99.3098, 40.0018],
            [-100.4956, 40.0017],
            [-102.0517, 40.0031],
            [-102.0517, 41.0024],
            [-102.8272, 41.0021],
            [-104.0533, 41.0014],
            [-104.0529, 42.147],
          ],
        ],
      },
      properties: { STATE_ID: '31', STATE_NAME: 'Nebraska' },
      id: 31,
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-80.5199, 42.3271],
            [-80.0802, 42.3942],
            [-79.7624, 42.5161],
            [-79.7613, 41.9988],
            [-78.8778, 41.9976],
            [-77.7767, 41.9984],
            [-76.4453, 41.9987],
            [-75.3596, 41.9995],
            [-75.2713, 41.8874],
            [-75.1144, 41.8436],
            [-74.9802, 41.4796],
            [-74.7409, 41.4312],
            [-74.6949, 41.3574],
            [-74.8301, 41.2872],
            [-74.8821, 41.1808],
            [-75.0268, 41.0397],
            [-75.1311, 40.9905],
            [-75.0515, 40.8641],
            [-75.1695, 40.7785],
            [-75.1941, 40.5761],
            [-75.0675, 40.54],
            [-75.0586, 40.4182],
            [-74.7712, 40.2149],
            [-74.8155, 40.1277],
            [-75.0756, 39.978],
            [-75.1373, 39.89],
            [-75.4151, 39.8019],
            [-75.5802, 39.8389],
            [-75.7886, 39.7222],
            [-76.9909, 39.7198],
            [-78.0958, 39.7221],
            [-79.4767, 39.7211],
            [-80.5193, 39.7214],
            [-80.5191, 39.958],
            [-80.5191, 40.5902],
            [-80.519, 40.6388],
            [-80.519, 41.404],
            [-80.5199, 42.3271],
          ],
        ],
      },
      properties: { STATE_ID: '42', STATE_NAME: 'Pennsylvania' },
      id: 42,
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-117.0323, 48.9992],
            [-117.0421, 48.033],
            [-117.0398, 47.4598],
            [-117.0398, 46.4259],
            [-116.9228, 46.1608],
            [-116.9161, 45.9954],
            [-117.6813, 45.9998],
            [-118.9873, 45.9999],
            [-119.1263, 45.9328],
            [-119.6236, 45.9056],
            [-119.67, 45.8569],
            [-119.9658, 45.8244],
            [-120.2108, 45.7259],
            [-120.5057, 45.7],
            [-120.6351, 45.7457],
            [-120.944, 45.6564],
            [-121.0849, 45.6479],
            [-121.5329, 45.7265],
            [-121.8112, 45.7067],
            [-122.1017, 45.5832],
            [-122.249, 45.5477],
            [-122.4387, 45.5636],
            [-122.7638, 45.6571],
            [-122.814, 45.961],
            [-122.9041, 46.0837],
            [-123.1159, 46.1853],
            [-123.2656, 46.1505],
            [-123.3714, 46.1464],
            [-123.48, 46.2692],
            [-123.8719, 46.2349],
            [-124.1595, 46.2611],
            [-124.13, 46.4329],
            [-124.1453, 46.6705],
            [-124.2344, 46.8912],
            [-124.2513, 47.0966],
            [-124.3064, 47.2481],
            [-124.4136, 47.3687],
            [-124.4614, 47.625],
            [-124.6988, 47.8498],
            [-124.8032, 47.9868],
            [-124.847, 48.1677],
            [-124.7614, 48.2957],
            [-124.817, 48.3855],
            [-124.7497, 48.4998],
            [-124.0138, 48.2975],
            [-123.5424, 48.2248],
            [-123.2477, 48.2847],
            [-123.1151, 48.4228],
            [-123.219, 48.5488],
            [-123.2679, 48.694],
            [-123.0085, 48.7671],
            [-123.0085, 48.8312],
            [-123.3222, 49.0021],
            [-121.7596, 48.9973],
            [-120.0596, 48.9995],
            [-118.9426, 49.0001],
            [-117.0323, 48.9992],
          ],
        ],
      },
      properties: { STATE_ID: '53', STATE_NAME: 'Washington' },
      id: 53,
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-91.1661, 33.0041],
            [-91.2128, 32.9221],
            [-91.1436, 32.8447],
            [-91.1527, 32.6408],
            [-91.0935, 32.4574],
            [-90.9867, 32.3518],
            [-90.9827, 32.2152],
            [-91.1649, 32.1957],
            [-91.0386, 32.0983],
            [-91.201, 31.9092],
            [-91.3452, 31.8439],
            [-91.3961, 31.6393],
            [-91.4869, 31.5867],
            [-91.549, 31.3472],
            [-91.6412, 31.2669],
            [-91.5902, 31.1756],
            [-91.6366, 30.9994],
            [-91.0602, 30.9989],
            [-89.7526, 31.0019],
            [-89.8313, 30.7676],
            [-89.8058, 30.5674],
            [-89.6836, 30.4518],
            [-89.647, 30.2918],
            [-89.5719, 30.1807],
            [-89.2884, 30.1785],
            [-89.1837, 30.2122],
            [-89.0965, 30.1658],
            [-88.889, 30.1398],
            [-88.7837, 30.0267],
            [-88.7673, 29.8408],
            [-88.8234, 29.715],
            [-89.1376, 29.3814],
            [-89.0694, 29.2943],
            [-88.942, 29.2537],
            [-88.9278, 29.1173],
            [-89.0961, 28.9425],
            [-89.2796, 28.9778],
            [-89.3847, 28.8715],
            [-89.4876, 28.8874],
            [-89.4634, 29.0828],
            [-89.5023, 29.1584],
            [-89.6104, 29.1986],
            [-90.0323, 29.135],
            [-90.1513, 29.0563],
            [-90.4094, 28.9953],
            [-90.5525, 29.0368],
            [-90.7208, 28.9926],
            [-90.9703, 29.005],
            [-91.02, 29.1218],
            [-91.3081, 29.2024],
            [-91.4211, 29.3028],
            [-91.7775, 29.3961],
            [-91.8409, 29.3602],
            [-92.1467, 29.532],
            [-92.2937, 29.4815],
            [-92.6325, 29.5297],
            [-93.1252, 29.7062],
            [-93.2874, 29.7243],
            [-93.7227, 29.6879],
            [-93.8144, 29.5966],
            [-93.9227, 29.8188],
            [-93.8551, 29.8641],
            [-93.7031, 30.0663],
            [-93.7502, 30.3167],
            [-93.6981, 30.4389],
            [-93.7338, 30.5313],
            [-93.5608, 30.8086],
            [-93.5489, 31.1866],
            [-93.6879, 31.3085],
            [-93.7147, 31.5142],
            [-93.8381, 31.6068],
            [-93.8226, 31.7736],
            [-93.9217, 31.9048],
            [-94.0427, 31.9993],
            [-94.043, 33.0192],
            [-93.0002, 33.0176],
            [-91.9555, 33.0075],
            [-91.1661, 33.0041],
          ],
        ],
      },
      properties: { STATE_ID: '22', STATE_NAME: 'Louisiana' },
      id: 22,
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-85.6052, 34.9847],
            [-84.3218, 34.9884],
            [-83.62, 34.9867],
            [-83.1086, 35.0007],
            [-83.1271, 34.9387],
            [-83.3242, 34.7875],
            [-83.3382, 34.6872],
            [-83.0726, 34.5041],
            [-82.8644, 34.4598],
            [-82.7468, 34.2666],
            [-82.7175, 34.1506],
            [-82.5568, 33.9453],
            [-82.3469, 33.8343],
            [-82.2475, 33.7526],
            [-82.1961, 33.63],
            [-82.0463, 33.5638],
            [-81.9341, 33.4683],
            [-81.9447, 33.364],
            [-81.8345, 33.2584],
            [-81.8521, 33.2475],
            [-81.7046, 33.1165],
            [-81.4922, 33.0093],
            [-81.5086, 32.9526],
            [-81.4216, 32.8352],
            [-81.4187, 32.6294],
            [-81.1886, 32.4612],
            [-81.1225, 32.3066],
            [-81.1559, 32.2457],
            [-81.1172, 32.1176],
            [-80.9228, 32.0391],
            [-80.7514, 32.0335],
            [-80.7898, 31.9644],
            [-81.0498, 31.6886],
            [-81.088, 31.5273],
            [-81.1908, 31.3999],
            [-81.1828, 31.2659],
            [-81.2921, 31.1554],
            [-81.3048, 30.9857],
            [-81.4005, 30.7664],
            [-81.347, 30.7124],
            [-81.56, 30.7117],
            [-81.7183, 30.7447],
            [-81.9048, 30.8283],
            [-82.0368, 30.7544],
            [-82.0065, 30.5619],
            [-82.0664, 30.3558],
            [-82.1703, 30.359],
            [-82.2063, 30.4923],
            [-82.2147, 30.5686],
            [-83.1314, 30.6236],
            [-84.0727, 30.6755],
            [-84.8647, 30.7115],
            [-84.9368, 30.8185],
            [-85.0025, 31.0007],
            [-85.1005, 31.166],
            [-85.0922, 31.3646],
            [-85.0414, 31.5393],
            [-85.1259, 31.6963],
            [-85.1407, 31.8575],
            [-85.0659, 31.9712],
            [-85.0621, 32.1325],
            [-84.894, 32.2659],
            [-85.0028, 32.3224],
            [-84.9718, 32.4428],
            [-85.0672, 32.5793],
            [-85.1846, 32.8698],
            [-85.3574, 33.7501],
            [-85.6052, 34.9847],
          ],
        ],
      },
      properties: { STATE_ID: '13', STATE_NAME: 'Georgia' },
      id: 13,
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-88.2001, 34.9956],
            [-88.203, 35.008],
            [-86.8403, 34.9919],
            [-85.6052, 34.9847],
            [-85.3574, 33.7501],
            [-85.1846, 32.8698],
            [-85.0672, 32.5793],
            [-84.9718, 32.4428],
            [-85.0028, 32.3224],
            [-84.894, 32.2659],
            [-85.0621, 32.1325],
            [-85.0659, 31.9712],
            [-85.1407, 31.8575],
            [-85.1259, 31.6963],
            [-85.0414, 31.5393],
            [-85.0922, 31.3646],
            [-85.1005, 31.166],
            [-85.0025, 31.0007],
            [-85.546, 30.9964],
            [-86.5202, 30.9933],
            [-87.5988, 30.9975],
            [-87.6262, 30.8467],
            [-87.5326, 30.7432],
            [-87.4064, 30.6744],
            [-87.4444, 30.5149],
            [-87.3712, 30.4305],
            [-87.5, 30.329],
            [-87.5183, 30.2295],
            [-88.0749, 30.1471],
            [-88.162, 30.2001],
            [-88.3844, 30.1585],
            [-88.4487, 31.4207],
            [-88.4731, 31.896],
            [-88.4038, 32.4488],
            [-88.2796, 33.4941],
            [-88.2107, 34.0292],
            [-88.0979, 34.8921],
            [-88.2001, 34.9956],
          ],
        ],
      },
      properties: { STATE_ID: '01', STATE_NAME: 'Alabama' },
      id: 1,
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-114.0415, 41.9939],
            [-113.9016, 41.9885],
            [-112.7098, 42.0001],
            [-112.1539, 41.9972],
            [-111.0467, 42.0017],
            [-111.0468, 40.9979],
            [-110.5051, 40.9948],
            [-109.05, 41.0007],
            [-109.0509, 40.0287],
            [-109.0512, 39.1426],
            [-109.0602, 38.2755],
            [-109.0428, 38.1663],
            [-109.0452, 36.999],
            [-110.4697, 36.9977],
            [-111.9654, 37.001],
            [-112.9961, 37.0001],
            [-114.05, 37.0002],
            [-114.0502, 37.8989],
            [-114.0499, 38.6518],
            [-114.0476, 39.6261],
            [-114.0461, 40.4027],
            [-114.0412, 41.1537],
            [-114.0415, 41.9939],
          ],
        ],
      },
      properties: { STATE_ID: '49', STATE_NAME: 'Utah' },
      id: 49,
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-84.806, 41.6961],
            [-83.4159, 41.7338],
            [-83.1125, 41.9594],
            [-83.0693, 41.8642],
            [-82.6811, 41.6763],
            [-82.3983, 41.6765],
            [-81.2498, 42.2164],
            [-80.5199, 42.3271],
            [-80.519, 41.404],
            [-80.519, 40.6388],
            [-80.664, 40.5903],
            [-80.5992, 40.4826],
            [-80.5999, 40.3177],
            [-80.7373, 40.0801],
            [-80.7401, 39.9708],
            [-80.8702, 39.7631],
            [-80.8804, 39.6207],
            [-81.0236, 39.5526],
            [-81.2214, 39.3862],
            [-81.3449, 39.3468],
            [-81.4561, 39.4093],
            [-81.6834, 39.2711],
            [-81.7564, 39.1776],
            [-81.764, 39.0142],
            [-81.8445, 38.9288],
            [-82.006, 39.0294],
            [-82.0902, 38.9753],
            [-82.1442, 38.8419],
            [-82.2173, 38.7957],
            [-82.1727, 38.6297],
            [-82.2935, 38.5754],
            [-82.324, 38.4493],
            [-82.5935, 38.4218],
            [-82.7255, 38.5582],
            [-82.8422, 38.5886],
            [-82.8963, 38.7564],
            [-83.0309, 38.7255],
            [-83.1547, 38.6207],
            [-83.2622, 38.6225],
            [-83.5213, 38.7031],
            [-83.6671, 38.6264],
            [-83.7702, 38.6559],
            [-83.8529, 38.7523],
            [-84.2131, 38.8058],
            [-84.3287, 39.0287],
            [-84.4588, 39.1215],
            [-84.6079, 39.0733],
            [-84.7581, 39.145],
            [-84.8202, 39.1055],
            [-84.8046, 40.2522],
            [-84.8032, 40.959],
            [-84.806, 41.6961],
          ],
        ],
      },
      properties: { STATE_ID: '39', STATE_NAME: 'Ohio' },
      id: 39,
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-103.0024, 36.5005],
            [-102.2206, 36.5003],
            [-101.3754, 36.4993],
            [-100.0004, 36.4997],
            [-100.0004, 35.4224],
            [-100.0003, 34.5605],
            [-99.9232, 34.5746],
            [-99.695, 34.3783],
            [-99.3771, 34.4586],
            [-99.2108, 34.3372],
            [-99.1912, 34.2153],
            [-98.9513, 34.2122],
            [-98.8871, 34.1683],
            [-98.6901, 34.1332],
            [-98.5998, 34.1606],
            [-98.4863, 34.0626],
            [-98.364, 34.1571],
            [-98.169, 34.1142],
            [-98.0844, 34.0029],
            [-97.9717, 34.0054],
            [-97.8774, 33.8502],
            [-97.6615, 33.9908],
            [-97.5009, 33.9195],
            [-97.3747, 33.8186],
            [-97.3022, 33.8839],
            [-97.0861, 33.7446],
            [-96.9243, 33.9592],
            [-96.8664, 33.8531],
            [-96.7102, 33.8323],
            [-96.6599, 33.9167],
            [-96.5002, 33.7726],
            [-96.2988, 33.7586],
            [-96.1797, 33.7597],
            [-96.1481, 33.8378],
            [-95.9523, 33.8573],
            [-95.8301, 33.8348],
            [-95.5528, 33.9243],
            [-95.4383, 33.8663],
            [-95.2805, 33.8756],
            [-95.2169, 33.9627],
            [-94.9439, 33.8302],
            [-94.8698, 33.7461],
            [-94.4859, 33.6379],
            [-94.3895, 33.5467],
            [-94.0434, 33.5521],
            [-94.043, 33.0192],
            [-94.0427, 31.9993],
            [-93.9217, 31.9048],
            [-93.8226, 31.7736],
            [-93.8381, 31.6068],
            [-93.7147, 31.5142],
            [-93.6879, 31.3085],
            [-93.5489, 31.1866],
            [-93.5608, 30.8086],
            [-93.7338, 30.5313],
            [-93.6981, 30.4389],
            [-93.7502, 30.3167],
            [-93.7031, 30.0663],
            [-93.8551, 29.8641],
            [-93.9227, 29.8188],
            [-93.8144, 29.5966],
            [-94.0336, 29.625],
            [-94.5002, 29.4516],
            [-94.6503, 29.3828],
            [-94.6662, 29.2811],
            [-95.0864, 29.0682],
            [-95.1055, 29.016],
            [-95.3654, 28.8138],
            [-95.4201, 28.8056],
            [-95.7502, 28.6384],
            [-96.1822, 28.452],
            [-96.3752, 28.2908],
            [-96.531, 28.2212],
            [-96.7463, 28.0796],
            [-96.9595, 27.8753],
            [-97.1253, 27.6406],
            [-97.2503, 27.3923],
            [-97.2966, 27.2503],
            [-97.3232, 27.0103],
            [-97.3004, 26.8346],
            [-97.1261, 26.2504],
            [-97.0911, 25.9738],
            [-97.2769, 25.9523],
            [-97.427, 25.8414],
            [-97.5208, 25.886],
            [-97.667, 26.0189],
            [-97.8787, 26.0653],
            [-98.0392, 26.0413],
            [-98.2487, 26.072],
            [-98.3546, 26.153],
            [-98.5991, 26.2576],
            [-98.6811, 26.2628],
            [-98.8073, 26.3694],
            [-98.8909, 26.3564],
            [-99.0811, 26.3966],
            [-99.1667, 26.5361],
            [-99.2089, 26.7248],
            [-99.2686, 26.8432],
            [-99.4523, 27.061],
            [-99.4415, 27.2499],
            [-99.508, 27.353],
            [-99.4792, 27.4786],
            [-99.6035, 27.642],
            [-99.7215, 27.6661],
            [-99.878, 27.8005],
            [-99.8941, 27.8929],
            [-100.0869, 28.1468],
            [-100.2113, 28.1939],
            [-100.2943, 28.2844],
            [-100.3362, 28.4302],
            [-100.5468, 28.8254],
            [-100.6515, 28.9456],
            [-100.6755, 29.1011],
            [-100.7976, 29.2468],
            [-101.004, 29.3647],
            [-101.0601, 29.4587],
            [-101.1516, 29.4767],
            [-101.3116, 29.5851],
            [-101.4132, 29.7534],
            [-101.5678, 29.7987],
            [-101.6457, 29.7542],
            [-101.8036, 29.8035],
            [-102.0713, 29.787],
            [-102.2997, 29.8772],
            [-102.4049, 29.765],
            [-102.4873, 29.7866],
            [-102.6772, 29.7411],
            [-102.8089, 29.5208],
            [-102.8462, 29.3574],
            [-102.9064, 29.2621],
            [-103.0802, 29.0871],
            [-103.1185, 28.9837],
            [-103.239, 28.9813],
            [-103.4346, 29.0576],
            [-103.55, 29.1538],
            [-103.7037, 29.1844],
            [-103.8142, 29.2739],
            [-103.9723, 29.296],
            [-104.1607, 29.3899],
            [-104.2089, 29.4807],
            [-104.3382, 29.5202],
            [-104.5442, 29.6815],
            [-104.5655, 29.7696],
            [-104.6798, 29.9209],
            [-104.687, 30.1788],
            [-104.8109, 30.3632],
            [-104.8811, 30.5216],
            [-104.8812, 30.5216],
            [-104.8811, 30.5216],
            [-104.8812, 30.5216],
            [-105.12, 30.75],
            [-105.3958, 30.8492],
            [-105.5544, 30.9882],
            [-105.6488, 31.1159],
            [-105.7737, 31.1676],
            [-105.9535, 31.3645],
            [-106.2052, 31.4651],
            [-106.3811, 31.7321],
            [-106.5282, 31.7831],
            [-106.6359, 31.8662],
            [-106.6185, 32.0005],
            [-105.2458, 32.0002],
            [-104.3291, 32.0001],
            [-103.0645, 32.0004],
            [-103.0647, 32.9953],
            [-103.0436, 34.0036],
            [-103.0431, 34.6718],
            [-103.0419, 35.5356],
            [-103.0417, 36.5004],
            [-103.0024, 36.5005],
          ],
        ],
      },
      properties: { STATE_ID: '48', STATE_NAME: 'Texas' },
      id: 48,
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-109.05, 41.0007],
            [-108.5619, 41],
            [-107.5006, 41.0023],
            [-106.4539, 41.0021],
            [-105.7044, 40.9969],
            [-104.8553, 40.998],
            [-104.0533, 41.0014],
            [-102.8272, 41.0021],
            [-102.0517, 41.0024],
            [-102.0517, 40.0031],
            [-102.0453, 38.8569],
            [-102.0416, 37.6967],
            [-102.0421, 36.993],
            [-103.0023, 37.0001],
            [-104.3388, 36.9935],
            [-105.0292, 36.9927],
            [-105.801, 36.9956],
            [-106.8698, 36.9925],
            [-106.8772, 37.0001],
            [-107.858, 36.9997],
            [-109.0452, 36.999],
            [-109.0428, 38.1663],
            [-109.0602, 38.2755],
            [-109.0512, 39.1426],
            [-109.0509, 40.0287],
            [-109.05, 41.0007],
          ],
        ],
      },
      properties: { STATE_ID: '08', STATE_NAME: 'Colorado' },
      id: 8,
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-83.1086, 35.0007],
            [-82.4596, 35.1781],
            [-82.2749, 35.2001],
            [-81.0707, 35.1513],
            [-80.935, 35.1074],
            [-80.782, 34.9358],
            [-80.7975, 34.8197],
            [-79.6753, 34.8047],
            [-79.2015, 34.4086],
            [-78.4993, 33.8128],
            [-78.7724, 33.708],
            [-79.0345, 33.4568],
            [-79.1202, 33.2469],
            [-79.0776, 33.1887],
            [-79.308, 32.9697],
            [-79.4402, 32.9589],
            [-79.5347, 32.87],
            [-79.7601, 32.7383],
            [-79.7982, 32.6756],
            [-79.979, 32.5581],
            [-80.1988, 32.4962],
            [-80.5238, 32.2346],
            [-80.585, 32.1626],
            [-80.7482, 32.0845],
            [-80.7514, 32.0335],
            [-80.9228, 32.0391],
            [-81.1172, 32.1176],
            [-81.1559, 32.2457],
            [-81.1225, 32.3066],
            [-81.1886, 32.4612],
            [-81.4187, 32.6294],
            [-81.4216, 32.8352],
            [-81.5086, 32.9526],
            [-81.4922, 33.0093],
            [-81.7046, 33.1165],
            [-81.8521, 33.2475],
            [-81.8345, 33.2584],
            [-81.9447, 33.364],
            [-81.9341, 33.4683],
            [-82.0463, 33.5638],
            [-82.1961, 33.63],
            [-82.2475, 33.7526],
            [-82.3469, 33.8343],
            [-82.5568, 33.9453],
            [-82.7175, 34.1506],
            [-82.7468, 34.2666],
            [-82.8644, 34.4598],
            [-83.0726, 34.5041],
            [-83.3382, 34.6872],
            [-83.3242, 34.7875],
            [-83.1271, 34.9387],
            [-83.1086, 35.0007],
          ],
        ],
      },
      properties: { STATE_ID: '45', STATE_NAME: 'South Carolina' },
      id: 45,
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-103.0024, 36.5005],
            [-103.0023, 37.0001],
            [-102.0421, 36.993],
            [-101.1254, 36.9975],
            [-99.6477, 37],
            [-98.31, 36.9977],
            [-97.4899, 36.9986],
            [-96.5001, 36.9986],
            [-95.381, 36.9993],
            [-94.618, 36.9989],
            [-94.6179, 36.4994],
            [-94.431, 35.3925],
            [-94.4661, 34.3481],
            [-94.4859, 33.6379],
            [-94.8698, 33.7461],
            [-94.9439, 33.8302],
            [-95.2169, 33.9627],
            [-95.2805, 33.8756],
            [-95.4383, 33.8663],
            [-95.5528, 33.9243],
            [-95.8301, 33.8348],
            [-95.9523, 33.8573],
            [-96.1481, 33.8378],
            [-96.1797, 33.7597],
            [-96.2988, 33.7586],
            [-96.5002, 33.7726],
            [-96.6599, 33.9167],
            [-96.7102, 33.8323],
            [-96.8664, 33.8531],
            [-96.9243, 33.9592],
            [-97.0861, 33.7446],
            [-97.3022, 33.8839],
            [-97.3747, 33.8186],
            [-97.5009, 33.9195],
            [-97.6615, 33.9908],
            [-97.8774, 33.8502],
            [-97.9717, 34.0054],
            [-98.0844, 34.0029],
            [-98.169, 34.1142],
            [-98.364, 34.1571],
            [-98.4863, 34.0626],
            [-98.5998, 34.1606],
            [-98.6901, 34.1332],
            [-98.8871, 34.1683],
            [-98.9513, 34.2122],
            [-99.1912, 34.2153],
            [-99.2108, 34.3372],
            [-99.3771, 34.4586],
            [-99.695, 34.3783],
            [-99.9232, 34.5746],
            [-100.0003, 34.5605],
            [-100.0004, 35.4224],
            [-100.0004, 36.4997],
            [-101.3754, 36.4993],
            [-102.2206, 36.5003],
            [-103.0024, 36.5005],
          ],
        ],
      },
      properties: { STATE_ID: '40', STATE_NAME: 'Oklahoma' },
      id: 40,
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-84.3218, 34.9884],
            [-85.6052, 34.9847],
            [-86.8403, 34.9919],
            [-88.203, 35.008],
            [-88.2001, 34.9956],
            [-88.9252, 34.9948],
            [-90.3093, 34.9957],
            [-90.2094, 35.0266],
            [-90.1736, 35.1181],
            [-90.0666, 35.136],
            [-90.1521, 35.256],
            [-90.075, 35.3841],
            [-90.1295, 35.4419],
            [-90.0396, 35.5486],
            [-89.9408, 35.5561],
            [-89.954, 35.7362],
            [-89.7057, 35.8185],
            [-89.7656, 35.8914],
            [-89.7331, 36.0006],
            [-89.5935, 36.1281],
            [-89.6065, 36.2383],
            [-89.5364, 36.2728],
            [-89.5392, 36.4979],
            [-89.4854, 36.4975],
            [-89.4173, 36.499],
            [-88.0533, 36.4971],
            [-88.0705, 36.6783],
            [-87.8533, 36.6332],
            [-86.3321, 36.6487],
            [-85.8324, 36.6221],
            [-85.2769, 36.6268],
            [-84.5365, 36.5963],
            [-83.6908, 36.5826],
            [-83.6754, 36.6008],
            [-83.2613, 36.5939],
            [-81.6775, 36.5881],
            [-81.696, 36.4916],
            [-81.7223, 36.3523],
            [-81.8485, 36.3389],
            [-82.0338, 36.1205],
            [-82.2221, 36.157],
            [-82.4096, 36.0832],
            [-82.55, 35.9638],
            [-82.6372, 36.0658],
            [-82.8982, 35.9452],
            [-82.9956, 35.7731],
            [-83.1596, 35.7647],
            [-83.4985, 35.5629],
            [-83.6967, 35.5701],
            [-83.8807, 35.5185],
            [-84.0215, 35.4042],
            [-84.0234, 35.2963],
            [-84.0934, 35.2489],
            [-84.2903, 35.2252],
            [-84.3218, 34.9884],
          ],
        ],
      },
      properties: { STATE_ID: '47', STATE_NAME: 'Tennessee' },
      id: 47,
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-111.0524, 44.4784],
            [-111.0546, 45.001],
            [-110.7045, 44.9922],
            [-109.1032, 45.0058],
            [-108.3754, 44.9999],
            [-106.5974, 44.9948],
            [-105.9132, 45.0002],
            [-104.5919, 44.9986],
            [-104.0579, 44.9976],
            [-104.0545, 44.1804],
            [-104.053, 43.0006],
            [-104.0529, 42.147],
            [-104.0533, 41.0014],
            [-104.8553, 40.998],
            [-105.7044, 40.9969],
            [-106.4539, 41.0021],
            [-107.5006, 41.0023],
            [-108.5619, 41],
            [-109.05, 41.0007],
            [-110.5051, 40.9948],
            [-111.0468, 40.9979],
            [-111.0467, 42.0017],
            [-111.044, 43.2334],
            [-111.0524, 44.4784],
          ],
        ],
      },
      properties: { STATE_ID: '56', STATE_NAME: 'Wyoming' },
      id: 56,
    },
    {
      type: 'Feature',
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-166.3829, 23.8297],
              [-166.3071, 23.9249],
              [-166.1824, 23.9023],
              [-166.1068, 23.7425],
              [-166.2054, 23.6916],
              [-166.3158, 23.7401],
              [-166.3829, 23.8297],
            ],
          ],
          [
            [
              [-174.0659, 26.0394],
              [-173.9579, 26.1357],
              [-173.926, 26.0196],
              [-174.0659, 26.0394],
            ],
          ],
          [
            [
              [-176.0396, 27.7582],
              [-175.9663, 27.9299],
              [-175.8067, 28.0072],
              [-175.6701, 27.9416],
              [-175.7341, 27.7388],
              [-175.9696, 27.6934],
              [-176.0396, 27.7582],
            ],
          ],
          [
            [
              [-178.4332, 28.3978],
              [-178.3958, 28.5],
              [-178.2385, 28.4562],
              [-178.3014, 28.3283],
              [-178.4332, 28.3978],
            ],
          ],
          [
            [
              [-155.4329, 20.1452],
              [-155.1403, 19.9895],
              [-155.0316, 19.875],
              [-154.9289, 19.6736],
              [-154.7593, 19.5365],
              [-154.7757, 19.4571],
              [-154.9397, 19.3128],
              [-155.1821, 19.2099],
              [-155.258, 19.2222],
              [-155.4949, 19.0716],
              [-155.5656, 18.9345],
              [-155.6943, 18.8668],
              [-155.9216, 19.0038],
              [-155.9739, 19.1426],
              [-155.9432, 19.3442],
              [-156.0359, 19.598],
              [-156.1191, 19.7193],
              [-156.1055, 19.7973],
              [-155.9624, 19.9092],
              [-155.8843, 20.0183],
              [-155.9323, 20.0834],
              [-155.9577, 20.235],
              [-155.9159, 20.3011],
              [-155.7515, 20.2945],
              [-155.5788, 20.1802],
              [-155.4329, 20.1452],
            ],
          ],
          [
            [
              [-158.3176, 21.5391],
              [-158.3243, 21.6157],
              [-158.1489, 21.642],
              [-158.0501, 21.7399],
              [-157.9418, 21.7546],
              [-157.7509, 21.5165],
              [-157.661, 21.4968],
              [-157.6077, 21.2691],
              [-157.6758, 21.2138],
              [-157.8102, 21.1992],
              [-157.8987, 21.2499],
              [-158.1101, 21.2371],
              [-158.1644, 21.2723],
              [-158.3176, 21.5391],
            ],
          ],
          [
            [
              [-159.625, 22.2618],
              [-159.4029, 22.2905],
              [-159.2458, 22.1668],
              [-159.2843, 21.9406],
              [-159.4329, 21.8208],
              [-159.6313, 21.8467],
              [-159.7936, 21.9398],
              [-159.8481, 22.0761],
              [-159.75, 22.1994],
              [-159.625, 22.2618],
            ],
          ],
          [
            [
              [-156.5627, 20.738],
              [-156.625, 20.75],
              [-156.75, 20.9051],
              [-156.735, 21.0057],
              [-156.591, 21.0924],
              [-156.4511, 20.9688],
              [-156.3392, 21.0042],
              [-156.1953, 20.9715],
              [-155.9268, 20.7817],
              [-156.0163, 20.6124],
              [-156.2905, 20.5369],
              [-156.4599, 20.5577],
              [-156.5335, 20.463],
              [-156.75, 20.4543],
              [-156.7044, 20.6046],
              [-156.5627, 20.738],
            ],
          ],
          [
            [
              [-157.1115, 20.875],
              [-157.0353, 20.9833],
              [-156.8233, 20.9308],
              [-156.75, 20.8076],
              [-156.875, 20.691],
              [-156.9944, 20.6936],
              [-157.1115, 20.875],
            ],
          ],
          [
            [
              [-160.3045, 21.8326],
              [-160.28, 21.9206],
              [-160.0567, 22.0683],
              [-159.9972, 21.9925],
              [-160.031, 21.8694],
              [-160.1703, 21.7396],
              [-160.2658, 21.7462],
              [-160.3045, 21.8326],
            ],
          ],
          [
            [
              [-157.0151, 21.2612],
              [-156.9103, 21.2296],
              [-156.7221, 21.2261],
              [-156.6726, 21.0957],
              [-156.837, 21],
              [-157.0721, 21.0576],
              [-157.3212, 21.0507],
              [-157.3657, 21.125],
              [-157.2722, 21.2766],
              [-157.0151, 21.2612],
            ],
          ],
        ],
      },
      properties: { STATE_ID: '15', STATE_NAME: 'Hawaii' },
      id: 15,
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-104.0487, 48.9996],
            [-102.9811, 48.9992],
            [-102.217, 48.9986],
            [-101.1254, 48.9991],
            [-99.9137, 48.999],
            [-98.6943, 49.0001],
            [-97.2287, 49.0006],
            [-97.2342, 48.9483],
            [-97.1014, 48.6822],
            [-97.1478, 48.614],
            [-97.1498, 48.1835],
            [-97.0549, 47.9466],
            [-96.8548, 47.6069],
            [-96.834, 47.0098],
            [-96.7802, 46.877],
            [-96.7995, 46.6752],
            [-96.7181, 46.4419],
            [-96.6017, 46.3312],
            [-96.5637, 45.9352],
            [-97.4795, 45.9351],
            [-98.3115, 45.9361],
            [-99.846, 45.9411],
            [-100.6054, 45.9436],
            [-102.0196, 45.9445],
            [-102.9647, 45.945],
            [-104.0456, 45.9453],
            [-104.0452, 46.5827],
            [-104.0414, 47.8631],
            [-104.0487, 48.9996],
          ],
        ],
      },
      properties: { STATE_ID: '38', STATE_NAME: 'North Dakota' },
      id: 38,
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-88.028, 37.7992],
            [-87.9078, 37.8068],
            [-87.833, 37.8771],
            [-87.3854, 37.9377],
            [-87.1326, 37.7901],
            [-87.0459, 37.8927],
            [-86.7949, 37.9892],
            [-86.7523, 37.9148],
            [-86.5067, 37.9306],
            [-86.525, 38.0289],
            [-86.3259, 38.154],
            [-86.1789, 38.0108],
            [-85.9471, 38.0051],
            [-85.9041, 38.1734],
            [-85.8195, 38.2819],
            [-85.6554, 38.3239],
            [-85.6183, 38.4263],
            [-85.4931, 38.4729],
            [-85.416, 38.5637],
            [-85.4354, 38.7291],
            [-85.2893, 38.7424],
            [-85.1731, 38.688],
            [-84.9919, 38.7785],
            [-84.8125, 38.7867],
            [-84.8202, 39.1055],
            [-84.7581, 39.145],
            [-84.6079, 39.0733],
            [-84.4588, 39.1215],
            [-84.3287, 39.0287],
            [-84.2131, 38.8058],
            [-83.8529, 38.7523],
            [-83.7702, 38.6559],
            [-83.6671, 38.6264],
            [-83.5213, 38.7031],
            [-83.2622, 38.6225],
            [-83.1547, 38.6207],
            [-83.0309, 38.7255],
            [-82.8963, 38.7564],
            [-82.8422, 38.5886],
            [-82.7255, 38.5582],
            [-82.5935, 38.4218],
            [-82.5745, 38.2645],
            [-82.6366, 38.138],
            [-82.5494, 38.0686],
            [-82.5022, 37.9331],
            [-82.3406, 37.786],
            [-82.3017, 37.6808],
            [-82.1341, 37.5536],
            [-81.968, 37.538],
            [-82.3486, 37.268],
            [-82.7214, 37.1208],
            [-82.7428, 37.0428],
            [-82.871, 36.9688],
            [-82.8791, 36.8892],
            [-83.0728, 36.8545],
            [-83.1942, 36.7395],
            [-83.6754, 36.6008],
            [-83.6908, 36.5826],
            [-84.5365, 36.5963],
            [-85.2769, 36.6268],
            [-85.8324, 36.6221],
            [-86.3321, 36.6487],
            [-87.8533, 36.6332],
            [-88.0705, 36.6783],
            [-88.0533, 36.4971],
            [-89.4173, 36.499],
            [-89.3642, 36.6259],
            [-89.2271, 36.5695],
            [-89.1993, 36.7156],
            [-89.1171, 36.8887],
            [-89.1329, 36.9821],
            [-89.1738, 37.0663],
            [-89.0299, 37.2111],
            [-88.9314, 37.2274],
            [-88.5232, 37.0658],
            [-88.4252, 37.1538],
            [-88.5158, 37.2841],
            [-88.477, 37.3857],
            [-88.2792, 37.4533],
            [-88.082, 37.4728],
            [-88.1572, 37.6333],
            [-88.028, 37.7992],
          ],
        ],
      },
      properties: { STATE_ID: '21', STATE_NAME: 'Kentucky' },
      id: 21,
    },
    {
      type: 'Feature',
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-64.9497, 17.6533],
              [-64.8993, 17.8155],
              [-64.7005, 17.8092],
              [-64.5835, 17.8331],
              [-64.5487, 17.703],
              [-64.7564, 17.6306],
              [-64.9497, 17.6533],
            ],
          ],
          [
            [
              [-64.8437, 18.3937],
              [-64.6404, 18.364],
              [-64.6782, 18.2523],
              [-64.8013, 18.2451],
              [-64.8864, 18.1914],
              [-64.9465, 18.2572],
              [-65.1541, 18.2774],
              [-65.1004, 18.4395],
              [-64.891, 18.4622],
              [-64.8437, 18.3937],
            ],
          ],
        ],
      },
      properties: {
        STATE_ID: '78',
        STATE_NAME: 'United States Virgin Islands',
      },
      id: 78,
    },
    {
      type: 'Feature',
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [145.5751, 16.3058],
              [145.5713, 16.402],
              [145.7208, 16.4483],
              [145.7676, 16.324],
              [145.6932, 16.274],
              [145.5751, 16.3058],
            ],
          ],
          [
            [
              [145.7044, 16.6882],
              [145.7924, 16.792],
              [145.8679, 16.7417],
              [145.8549, 16.6601],
              [145.7044, 16.6882],
            ],
          ],
          [
            [
              [145.7485, 17.5717],
              [145.8001, 17.6841],
              [145.9177, 17.6546],
              [145.8967, 17.5335],
              [145.7485, 17.5717],
            ],
          ],
          [
            [
              [145.7634, 17.3058],
              [145.8505, 17.4012],
              [145.9366, 17.3404],
              [145.8907, 17.2433],
              [145.7634, 17.3058],
            ],
          ],
          [
            [
              [145.6575, 18.0189],
              [145.7, 18.1997],
              [145.84, 18.2338],
              [145.8835, 18.1093],
              [145.7441, 17.986],
              [145.6575, 18.0189],
            ],
          ],
          [
            [
              [145.5769, 18.7296],
              [145.5981, 18.8568],
              [145.7257, 18.8598],
              [145.7698, 18.75],
              [145.6846, 18.6578],
              [145.5769, 18.7296],
            ],
          ],
          [
            [
              [145.3141, 19.6511],
              [145.3597, 19.7564],
              [145.4956, 19.6799],
              [145.4, 19.5884],
              [145.3141, 19.6511],
            ],
          ],
          [
            [
              [145.1552, 19.9776],
              [145.1787, 20.0965],
              [145.315, 20.033],
              [145.2481, 19.9481],
              [145.1552, 19.9776],
            ],
          ],
          [
            [
              [144.8155, 20.5277],
              [144.8633, 20.613],
              [144.9792, 20.5259],
              [144.9264, 20.4701],
              [144.8155, 20.5277],
            ],
          ],
          [
            [
              [145.0644, 14.0831],
              [145.0919, 14.1936],
              [145.2356, 14.2666],
              [145.3517, 14.2444],
              [145.3515, 14.125],
              [145.178, 14.0366],
              [145.0644, 14.0831],
            ],
          ],
          [
            [
              [146.003, 16.0058],
              [146.0508, 16.0934],
              [146.1507, 16.0063],
              [146.0444, 15.9479],
              [146.003, 16.0058],
            ],
          ],
          [
            [
              [145.5726, 15.125],
              [145.6483, 15.1387],
              [145.7354, 15.3126],
              [145.8528, 15.3519],
              [145.9094, 15.2741],
              [145.876, 15.1354],
              [145.75, 15.0659],
              [145.7308, 14.9173],
              [145.5917, 14.7814],
              [145.4678, 14.8371],
              [145.5522, 14.9327],
              [145.5183, 15.0563],
              [145.5726, 15.125],
            ],
          ],
        ],
      },
      properties: {
        STATE_ID: '69',
        STATE_NAME: 'Commonwealth of the Northern Mariana Islands',
      },
      id: 69,
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [144.5646, 13.4352],
            [144.6112, 13.5102],
            [144.7255, 13.5317],
            [144.8453, 13.7051],
            [144.9816, 13.6412],
            [144.9758, 13.5068],
            [144.8266, 13.3814],
            [144.8189, 13.2828],
            [144.7406, 13.1952],
            [144.6416, 13.1824],
            [144.5646, 13.4352],
          ],
        ],
      },
      properties: { STATE_ID: '66', STATE_NAME: 'Guam' },
      id: 66,
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-71.0839, 45.3055],
            [-70.8111, 45.3106],
            [-70.7943, 45.4284],
            [-70.5529, 45.6679],
            [-70.4005, 45.7197],
            [-70.417, 45.7955],
            [-70.2842, 45.8718],
            [-70.3073, 46.0611],
            [-70.2512, 46.1195],
            [-70.2449, 46.1279],
            [-70.2403, 46.1371],
            [-70.2904, 46.1851],
            [-70.209, 46.3295],
            [-70.0566, 46.4165],
            [-69.9972, 46.6954],
            [-69.2246, 47.4597],
            [-69.0356, 47.415],
            [-69.0338, 47.2404],
            [-68.8956, 47.1825],
            [-68.5862, 47.2835],
            [-68.3763, 47.2952],
            [-68.2345, 47.3553],
            [-67.952, 47.1946],
            [-67.7903, 47.0672],
            [-67.7812, 45.9436],
            [-67.7537, 45.9207],
            [-67.8037, 45.883],
            [-67.8035, 45.6777],
            [-67.4256, 45.5784],
            [-67.5001, 45.4909],
            [-67.4273, 45.3907],
            [-67.464, 45.2448],
            [-67.2913, 45.1849],
            [-67.291, 45.1856],
            [-67.1589, 45.162],
            [-66.9653, 44.8289],
            [-66.8854, 44.7942],
            [-67.2481, 44.5549],
            [-67.4397, 44.5054],
            [-67.5676, 44.3972],
            [-67.8056, 44.391],
            [-68.0182, 44.2751],
            [-68.1283, 44.2642],
            [-68.1759, 44.1251],
            [-68.3114, 44.0534],
            [-68.4368, 44.0532],
            [-68.6638, 43.8939],
            [-68.8269, 43.7357],
            [-68.9255, 43.7501],
            [-69.0559, 43.8589],
            [-69.3564, 43.7109],
            [-69.4093, 43.8108],
            [-69.5666, 43.7028],
            [-69.8552, 43.6469],
            [-70.156, 43.5244],
            [-70.3692, 43.3222],
            [-70.4932, 43.2868],
            [-70.493, 43.073],
            [-70.5751, 42.9171],
            [-70.8276, 43.1281],
            [-70.8183, 43.2384],
            [-70.9667, 43.3432],
            [-71.0068, 44.2157],
            [-71.034, 44.7049],
            [-71.0839, 45.3055],
          ],
        ],
      },
      properties: { STATE_ID: '23', STATE_NAME: 'Maine' },
      id: 23,
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-73.3431, 45.0108],
            [-73.3354, 44.8046],
            [-73.3898, 44.6172],
            [-73.3067, 44.5003],
            [-73.3109, 44.2743],
            [-73.3889, 44.1926],
            [-73.4379, 44.0451],
            [-73.3513, 43.7698],
            [-73.405, 43.6898],
            [-73.249, 43.554],
            [-73.2787, 42.8334],
            [-73.2648, 42.7458],
            [-73.5082, 42.0861],
            [-73.4873, 42.0495],
            [-73.551, 41.2954],
            [-73.4827, 41.2128],
            [-73.7278, 41.1007],
            [-73.6129, 40.9509],
            [-72.9995, 41.0871],
            [-72.0818, 41.2622],
            [-71.9073, 41.3045],
            [-71.791, 41.1841],
            [-71.7775, 41.0673],
            [-71.8371, 41.0109],
            [-72.3745, 40.8186],
            [-73.274, 40.5513],
            [-73.4865, 40.5205],
            [-73.7688, 40.5337],
            [-73.8867, 40.4898],
            [-73.9499, 40.5255],
            [-74.2281, 40.4774],
            [-74.2023, 40.6309],
            [-74.0557, 40.6518],
            [-73.9298, 40.8887],
            [-73.9027, 40.9973],
            [-74.6949, 41.3574],
            [-74.7409, 41.4312],
            [-74.9802, 41.4796],
            [-75.1144, 41.8436],
            [-75.2713, 41.8874],
            [-75.3596, 41.9995],
            [-76.4453, 41.9987],
            [-77.7767, 41.9984],
            [-78.8778, 41.9976],
            [-79.7613, 41.9988],
            [-79.7624, 42.5161],
            [-78.9998, 42.8044],
            [-78.9057, 42.9234],
            [-79.0116, 42.9853],
            [-79.0704, 43.2625],
            [-79.2009, 43.4504],
            [-78.6883, 43.6347],
            [-77.6963, 43.6313],
            [-76.7762, 43.6295],
            [-76.7535, 43.6843],
            [-76.4382, 44.0942],
            [-76.1618, 44.2808],
            [-75.913, 44.3681],
            [-75.7662, 44.5159],
            [-75.2735, 44.8531],
            [-74.9725, 44.9834],
            [-74.684, 44.9997],
            [-74.1495, 44.9914],
            [-73.3431, 45.0108],
          ],
        ],
      },
      properties: { STATE_ID: '36', STATE_NAME: 'New York' },
      id: 36,
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-117.0262, 42.0002],
            [-116.0471, 41.9963],
            [-115.0763, 41.9961],
            [-114.0415, 41.9939],
            [-114.0412, 41.1537],
            [-114.0461, 40.4027],
            [-114.0476, 39.6261],
            [-114.0499, 38.6518],
            [-114.0502, 37.8989],
            [-114.05, 37.0002],
            [-114.044, 36.194],
            [-114.1534, 36.0232],
            [-114.2439, 36.0153],
            [-114.3702, 36.1426],
            [-114.6271, 36.1408],
            [-114.7547, 36.0851],
            [-114.6955, 35.8299],
            [-114.6789, 35.5013],
            [-114.5956, 35.3265],
            [-114.5695, 35.1623],
            [-114.643, 35.0966],
            [-114.6335, 35.0019],
            [-115.4502, 35.6537],
            [-116.2508, 36.2769],
            [-117.0009, 36.8477],
            [-117.8759, 37.4973],
            [-118.3726, 37.856],
            [-119.126, 38.3932],
            [-120.001, 38.9996],
            [-120.0064, 39.2704],
            [-119.9974, 40.0392],
            [-119.9999, 40.9999],
            [-119.9992, 41.9949],
            [-118.6746, 41.9922],
            [-117.0262, 42.0002],
          ],
        ],
      },
      properties: { STATE_ID: '32', STATE_NAME: 'Nevada' },
      id: 32,
    },
    {
      type: 'Feature',
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [172.3539, 52.895],
              [172.5277, 53.0233],
              [172.7344, 53.0637],
              [173.1322, 53.044],
              [173.3219, 52.9921],
              [173.5806, 52.8155],
              [173.3302, 52.6928],
              [173.2096, 52.7396],
              [172.9307, 52.6848],
              [172.7369, 52.7279],
              [172.3539, 52.895],
            ],
          ],
          [
            [
              [173.78, 52.7634],
              [173.8706, 52.8348],
              [174.2327, 52.7573],
              [174.1625, 52.6567],
              [173.838, 52.716],
              [173.78, 52.7634],
            ],
          ],
          [
            [
              [179.4055, 51.9095],
              [179.4087, 52.0053],
              [179.6584, 52.0783],
              [179.8597, 51.9604],
              [179.7851, 51.8626],
              [179.6232, 51.819],
              [179.4055, 51.9095],
            ],
          ],
          [
            [
              [-170.9129, 52.5303],
              [-170.8889, 52.6493],
              [-170.6805, 52.7496],
              [-170.481, 52.6904],
              [-170.5361, 52.5739],
              [-170.7638, 52.4898],
              [-170.9129, 52.5303],
            ],
          ],
          [
            [
              [-170.2697, 52.7293],
              [-170.1769, 52.9351],
              [-170.0461, 52.9731],
              [-169.8319, 52.9355],
              [-169.8607, 53.105],
              [-169.7592, 53.1341],
              [-169.6045, 53.062],
              [-169.5886, 52.8006],
              [-169.7031, 52.7161],
              [-169.8487, 52.7631],
              [-170.0866, 52.6626],
              [-170.2697, 52.7293],
            ],
          ],
          [
            [
              [-179.1273, 51.5234],
              [-178.6601, 51.6659],
              [-178.4217, 51.5873],
              [-178.5502, 51.5085],
              [-178.8364, 51.492],
              [-178.9486, 51.5609],
              [-179.1273, 51.5234],
            ],
          ],
          [
            [
              [-179.2269, 51.2525],
              [-178.9694, 51.4571],
              [-178.8328, 51.3236],
              [-179.0736, 51.179],
              [-179.2269, 51.2525],
            ],
          ],
          [
            [
              [-170.5108, 57.1743],
              [-170.4496, 57.2493],
              [-170.191, 57.2974],
              [-170.0165, 57.2666],
              [-170.1118, 57.1367],
              [-170.4441, 56.9961],
              [-170.5108, 57.1743],
            ],
          ],
          [
            [
              [-173.1816, 60.6062],
              [-173.1586, 60.7288],
              [-172.9438, 60.6507],
              [-172.6878, 60.4801],
              [-172.3936, 60.4365],
              [-172.1091, 60.314],
              [-172.1895, 60.2508],
              [-172.5961, 60.2586],
              [-172.9102, 60.3396],
              [-173.1678, 60.4811],
              [-173.1816, 60.6062],
            ],
          ],
          [
            [
              [-178.9417, 51.7533],
              [-178.9395, 51.8336],
              [-178.7865, 51.8879],
              [-178.6468, 51.7903],
              [-178.828, 51.6948],
              [-178.9417, 51.7533],
            ],
          ],
          [
            [
              [-172.6672, 52.2098],
              [-172.7143, 52.2948],
              [-172.6332, 52.3879],
              [-172.3993, 52.4445],
              [-172.2497, 52.3935],
              [-172.2107, 52.3163],
              [-172.3779, 52.2284],
              [-172.6672, 52.2098],
            ],
          ],
          [
            [
              [-178.256, 51.812],
              [-178.2988, 51.9037],
              [-178.1072, 51.9765],
              [-177.947, 51.9713],
              [-177.8268, 51.8964],
              [-177.6149, 51.905],
              [-177.5153, 51.792],
              [-177.3089, 51.8368],
              [-177.2062, 51.9931],
              [-177.0169, 51.965],
              [-176.6013, 52.0566],
              [-176.4885, 52.026],
              [-176.4291, 51.9153],
              [-176.2429, 51.9267],
              [-176.2931, 52.0654],
              [-176.2244, 52.1531],
              [-176.0277, 52.1602],
              [-175.812, 52.048],
              [-175.5491, 52.0099],
              [-175.2966, 52.0888],
              [-174.9252, 52.1639],
              [-174.7966, 52.1535],
              [-174.3127, 52.4567],
              [-174.1106, 52.4683],
              [-173.9402, 52.3963],
              [-173.9028, 52.3192],
              [-173.9905, 52.1831],
              [-173.5063, 52.2131],
              [-173.4408, 52.1688],
              [-172.8666, 52.1489],
              [-172.9231, 52.0242],
              [-173.0797, 52.0179],
              [-173.1667, 51.9467],
              [-173.3045, 52.0054],
              [-173.4873, 51.9712],
              [-173.71, 52.0068],
              [-173.9352, 52.0044],
              [-174.0565, 52.0516],
              [-174.314, 52.0362],
              [-174.4809, 51.9525],
              [-174.8767, 51.9859],
              [-175.0564, 51.9482],
              [-175.3487, 51.9626],
              [-175.41, 51.9302],
              [-175.8024, 51.8604],
              [-175.9647, 51.7554],
              [-176.5398, 51.6484],
              [-176.6987, 51.5784],
              [-176.9696, 51.539],
              [-177.0637, 51.6647],
              [-177.2811, 51.6276],
              [-177.452, 51.6402],
              [-177.7277, 51.611],
              [-177.9283, 51.5419],
              [-178.1894, 51.6337],
              [-178.1517, 51.7738],
              [-178.256, 51.812],
            ],
          ],
          [
            [
              [178.5769, 51.5872],
              [178.5595, 51.7017],
              [178.6988, 51.7096],
              [179.0606, 51.6325],
              [179.1703, 51.523],
              [179.4252, 51.4628],
              [179.5784, 51.3543],
              [179.5109, 51.3083],
              [179.2182, 51.295],
              [178.896, 51.4925],
              [178.7902, 51.4925],
              [178.5769, 51.5872],
            ],
          ],
          [
            [
              [177.169, 51.8293],
              [177.1213, 51.9179],
              [177.4164, 52.0381],
              [177.5108, 52.1611],
              [177.7457, 52.1416],
              [177.8371, 51.9023],
              [177.4756, 51.8604],
              [177.2986, 51.7629],
              [177.169, 51.8293],
            ],
          ],
          [
            [
              [178.0386, 51.9604],
              [178.0206, 52.0719],
              [178.1888, 52.0987],
              [178.2672, 52.0374],
              [178.5124, 52.0434],
              [178.6669, 51.9303],
              [178.5316, 51.8467],
              [178.1959, 51.9488],
              [178.0386, 51.9604],
            ],
          ],
          [
            [
              [178.1848, 51.7702],
              [178.1758, 51.8846],
              [178.3684, 51.8605],
              [178.4964, 51.7445],
              [178.4005, 51.7036],
              [178.1848, 51.7702],
            ],
          ],
          [
            [
              [-169.853, 56.5788],
              [-169.8294, 56.6698],
              [-169.5093, 56.6675],
              [-169.3775, 56.5877],
              [-169.5266, 56.493],
              [-169.6761, 56.4962],
              [-169.853, 56.5788],
            ],
          ],
          [
            [
              [-171.9442, 63.4547],
              [-171.8559, 63.7857],
              [-171.6747, 63.8437],
              [-171.5356, 63.7219],
              [-170.9592, 63.6259],
              [-170.504, 63.7534],
              [-170.2969, 63.7556],
              [-169.9856, 63.623],
              [-169.9266, 63.5267],
              [-169.6023, 63.4806],
              [-169.4555, 63.415],
              [-168.6428, 63.3449],
              [-168.6093, 63.2294],
              [-168.7335, 63.1315],
              [-168.9228, 63.0996],
              [-169.2823, 63.1144],
              [-169.4199, 62.9739],
              [-169.591, 62.8879],
              [-169.839, 62.9259],
              [-169.9014, 63.0374],
              [-170.1154, 63.1272],
              [-170.328, 63.1387],
              [-170.5153, 63.2879],
              [-170.9083, 63.3699],
              [-171.0668, 63.3711],
              [-171.3989, 63.2576],
              [-171.8392, 63.3427],
              [-171.9442, 63.4547],
            ],
          ],
          [
            [
              [173.2806, 52.3776],
              [173.3159, 52.4646],
              [173.6082, 52.5599],
              [173.8478, 52.5372],
              [173.7812, 52.4479],
              [173.8141, 52.3533],
              [173.6386, 52.2942],
              [173.2806, 52.3776],
            ],
          ],
          [
            [
              [-171.3773, 52.4314],
              [-171.3744, 52.5371],
              [-171.1402, 52.6396],
              [-171.0224, 52.6037],
              [-171.2063, 52.3989],
              [-171.3773, 52.4314],
            ],
          ],
          [
            [
              [175.7745, 52.3656],
              [175.8419, 52.4403],
              [176.0605, 52.3722],
              [175.8966, 52.2813],
              [175.7745, 52.3656],
            ],
          ],
          [
            [
              [-152.4607, 58.8954],
              [-152.414, 58.9922],
              [-152.0196, 59.0109],
              [-151.8539, 58.9088],
              [-151.9816, 58.8373],
              [-152.3146, 58.82],
              [-152.4607, 58.8954],
            ],
          ],
          [
            [
              [-160.4915, 58.6335],
              [-160.416, 58.7984],
              [-160.1376, 58.7489],
              [-160.097, 58.6973],
              [-160.301, 58.5358],
              [-160.4915, 58.6335],
            ],
          ],
          [
            [
              [-146.508, 59.3864],
              [-146.4287, 59.5522],
              [-146.2572, 59.5684],
              [-146.1505, 59.448],
              [-146.2671, 59.3524],
              [-146.508, 59.3864],
            ],
          ],
          [
            [
              [-154.7986, 57.2275],
              [-154.9312, 57.2824],
              [-154.7031, 57.542],
              [-154.407, 57.6905],
              [-154.2401, 57.7155],
              [-153.8706, 57.9288],
              [-153.5389, 58.0074],
              [-153.5001, 58.0795],
              [-153.2, 58.2662],
              [-152.6984, 58.6486],
              [-152.4736, 58.7573],
              [-152.2477, 58.6807],
              [-152.1264, 58.4496],
              [-151.8952, 58.3717],
              [-151.7128, 58.374],
              [-151.6905, 58.2351],
              [-151.764, 58.1366],
              [-152.0335, 58.1118],
              [-152.4144, 58.0366],
              [-152.2202, 57.9302],
              [-152.031, 57.6286],
              [-152.2224, 57.4753],
              [-152.1565, 57.4059],
              [-152.2585, 57.3234],
              [-152.4156, 57.3845],
              [-152.8342, 57.0944],
              [-153.0983, 57.0403],
              [-153.181, 56.9607],
              [-153.4045, 56.9508],
              [-153.8004, 56.6899],
              [-153.8066, 56.5094],
              [-153.9363, 56.4535],
              [-154.4982, 56.4558],
              [-154.7257, 56.3552],
              [-154.8938, 56.4447],
              [-154.7581, 56.5598],
              [-154.5075, 56.6588],
              [-154.3601, 56.6335],
              [-154.2178, 56.7205],
              [-154.3855, 56.8303],
              [-154.3877, 56.8956],
              [-154.5775, 56.9528],
              [-154.6198, 57.1624],
              [-154.7986, 57.2275],
            ],
          ],
          [
            [
              [-166.2361, 54.0354],
              [-166.1651, 54.1951],
              [-165.9372, 54.277],
              [-165.7892, 54.2391],
              [-165.6556, 54.3471],
              [-165.4625, 54.3468],
              [-165.3452, 54.251],
              [-165.0669, 54.1801],
              [-164.8054, 54.2824],
              [-164.7145, 54.1661],
              [-164.881, 54.0434],
              [-165.381, 54.0164],
              [-165.5198, 53.9721],
              [-165.6572, 54.0363],
              [-165.789, 54.0162],
              [-165.961, 53.9161],
              [-165.9764, 53.7711],
              [-166.1749, 53.6598],
              [-166.3786, 53.6021],
              [-166.7018, 53.402],
              [-167.1068, 53.367],
              [-167.5221, 53.186],
              [-167.6693, 53.172],
              [-168.0263, 53.2261],
              [-168.2577, 53.1419],
              [-168.4027, 52.9293],
              [-168.5647, 52.9515],
              [-169.2281, 52.7146],
              [-169.3266, 52.7535],
              [-169.1971, 52.9649],
              [-169.0034, 52.9965],
              [-168.8595, 53.1882],
              [-168.6893, 53.3033],
              [-168.5043, 53.3629],
              [-168.4153, 53.5095],
              [-168.0904, 53.6197],
              [-167.9599, 53.6128],
              [-167.6982, 53.5242],
              [-167.7468, 53.4263],
              [-167.2304, 53.5667],
              [-167.1382, 53.7094],
              [-167.2301, 53.8882],
              [-167.054, 54.0086],
              [-166.7852, 54.066],
              [-166.3756, 54.0618],
              [-166.2361, 54.0354],
            ],
          ],
          [
            [
              [-162.9526, 54.3257],
              [-163.0085, 54.4002],
              [-162.8807, 54.5532],
              [-162.3544, 54.4567],
              [-162.2822, 54.331],
              [-162.7001, 54.238],
              [-162.9526, 54.3257],
            ],
          ],
          [
            [
              [-156.8714, 56.116],
              [-156.842, 56.2701],
              [-156.6906, 56.2572],
              [-156.5533, 56.0271],
              [-156.6754, 55.9568],
              [-156.8222, 55.9676],
              [-156.8714, 56.116],
            ],
          ],
          [
            [
              [-159.8552, 54.7577],
              [-159.8858, 54.8472],
              [-159.7611, 54.8913],
              [-159.5561, 54.8803],
              [-159.4168, 54.7922],
              [-159.4693, 54.721],
              [-159.8552, 54.7577],
            ],
          ],
          [
            [
              [-155.6346, 55.7058],
              [-155.8368, 55.8008],
              [-155.6768, 55.9077],
              [-155.4743, 55.9136],
              [-155.4748, 55.7415],
              [-155.6346, 55.7058],
            ],
          ],
          [
            [
              [-163.2624, 55.3942],
              [-163.1365, 55.525],
              [-163.0279, 55.4208],
              [-163.1539, 55.3431],
              [-163.2624, 55.3942],
            ],
          ],
          [
            [
              [-162.8321, 66.9268],
              [-163.0495, 66.9866],
              [-163.7815, 67.0698],
              [-163.9912, 67.1479],
              [-163.8825, 67.215],
              [-164.0045, 67.3978],
              [-164.2303, 67.5751],
              [-164.6552, 67.6924],
              [-165.4994, 68.0188],
              [-165.82, 68.0306],
              [-166.41, 68.2508],
              [-166.9733, 68.3097],
              [-166.95, 68.3592],
              [-166.4568, 68.4668],
              [-166.3699, 68.5712],
              [-166.2944, 68.9232],
              [-165.6668, 68.9146],
              [-164.2886, 68.9764],
              [-163.7598, 69.1218],
              [-163.3629, 69.3302],
              [-163.2823, 69.4563],
              [-163.2865, 69.6404],
              [-163.1835, 69.8064],
              [-162.5726, 70.1694],
              [-161.8749, 70.3807],
              [-161.1821, 70.3598],
              [-160.491, 70.5355],
              [-159.7124, 70.8468],
              [-159.1758, 70.9291],
              [-158.6859, 70.9611],
              [-158.4388, 70.8824],
              [-158.1518, 70.8709],
              [-157.5533, 70.9993],
              [-156.9453, 71.312],
              [-156.4604, 71.4411],
              [-155.8926, 71.354],
              [-155.7592, 71.2835],
              [-155.4361, 71.2706],
              [-154.7699, 71.1494],
              [-154.3724, 71.0105],
              [-153.9004, 70.9307],
              [-153.4943, 70.9326],
              [-153.2428, 70.9765],
              [-152.8227, 70.9748],
              [-152.1995, 70.8988],
              [-151.6156, 70.5971],
              [-151.545, 70.4903],
              [-151.2466, 70.48],
              [-150.2839, 70.6046],
              [-149.4222, 70.608],
              [-148.7867, 70.5266],
              [-148.13, 70.5],
              [-147.4256, 70.4429],
              [-146.749, 70.2952],
              [-145.9793, 70.2441],
              [-145.0329, 70.0295],
              [-144.6788, 70.0144],
              [-144.4812, 70.0907],
              [-144.0051, 70.1684],
              [-143.2264, 70.202],
              [-142.7063, 70.0882],
              [-142.0791, 69.9085],
              [-141.3779, 69.7442],
              [-141.0027, 69.7036],
              [-141.0027, 69.2238],
              [-141.0027, 68.1856],
              [-141.0027, 67.3973],
              [-141.0025, 66.1284],
              [-141.0023, 64.8666],
              [-141.0023, 63.9184],
              [-141.0022, 62.862],
              [-141.0021, 62.0383],
              [-141.0019, 61.0885],
              [-141.0018, 60.3061],
              [-140.5351, 60.2242],
              [-140.4723, 60.3106],
              [-139.9891, 60.1852],
              [-139.6984, 60.3404],
              [-139.0867, 60.3576],
              [-139.2003, 60.0907],
              [-139.0316, 59.9937],
              [-138.7021, 59.9102],
              [-138.6209, 59.7706],
              [-137.6043, 59.2431],
              [-137.4474, 58.9095],
              [-137.2647, 59.0023],
              [-136.8266, 59.1584],
              [-136.5815, 59.1649],
              [-136.4668, 59.2843],
              [-136.4743, 59.4642],
              [-136.3019, 59.4641],
              [-136.1903, 59.6399],
              [-135.9459, 59.6638],
              [-135.4774, 59.7996],
              [-135.0275, 59.5637],
              [-135.098, 59.4278],
              [-134.962, 59.2804],
              [-134.7024, 59.2478],
              [-134.4812, 59.1281],
              [-134.401, 58.9762],
              [-134.2505, 58.858],
              [-133.8404, 58.728],
              [-133.6998, 58.6073],
              [-133.3799, 58.4279],
              [-133.4615, 58.3855],
              [-133.1764, 58.1501],
              [-133.0764, 57.9998],
              [-132.8693, 57.8429],
              [-132.5592, 57.5039],
              [-132.2522, 57.2156],
              [-132.3713, 57.0952],
              [-132.051, 57.0511],
              [-132.1259, 56.8747],
              [-131.8717, 56.805],
              [-131.8351, 56.6018],
              [-131.5812, 56.6133],
              [-131.0857, 56.4065],
              [-130.7822, 56.3675],
              [-130.6225, 56.2679],
              [-130.4669, 56.2398],
              [-130.4256, 56.1407],
              [-130.2455, 56.0969],
              [-130.1028, 56.1167],
              [-130.0169, 56.0173],
              [-130.0247, 55.9159],
              [-130.1506, 55.767],
              [-130.1267, 55.5813],
              [-129.9801, 55.2842],
              [-130.0966, 55.1979],
              [-130.3395, 54.9214],
              [-130.5694, 54.7909],
              [-130.6154, 54.7055],
              [-131.313, 54.694],
              [-132.9257, 54.659],
              [-133.6489, 54.632],
              [-133.7905, 55.0006],
              [-133.6137, 55.1703],
              [-133.8739, 55.3988],
              [-133.7982, 55.6332],
              [-133.8859, 55.7802],
              [-134.0466, 55.8505],
              [-134.192, 55.693],
              [-134.5694, 55.804],
              [-134.6457, 55.8715],
              [-134.4942, 55.9996],
              [-134.6612, 56.1422],
              [-134.8675, 56.2474],
              [-135.87, 57.0006],
              [-136.4545, 57.8308],
              [-136.6586, 57.9014],
              [-136.6789, 58.0363],
              [-136.6163, 58.1472],
              [-136.8144, 58.1888],
              [-137.0145, 58.3443],
              [-137.1496, 58.3413],
              [-137.7622, 58.5965],
              [-137.996, 58.7495],
              [-138.026, 58.8524],
              [-138.2911, 59.0004],
              [-138.6672, 59.0831],
              [-138.8906, 59.1807],
              [-139.5314, 59.3572],
              [-140.3631, 59.6536],
              [-140.6564, 59.6574],
              [-142.0824, 59.9411],
              [-142.669, 60.0115],
              [-143.9432, 59.9198],
              [-144.1441, 59.9194],
              [-144.4105, 59.7463],
              [-144.6768, 59.7274],
              [-144.7778, 59.8299],
              [-144.5397, 60.0102],
              [-144.776, 60.1296],
              [-145.3177, 60.1797],
              [-145.8243, 60.3151],
              [-146.1793, 60.2801],
              [-146.9393, 60.1039],
              [-147.2404, 59.9994],
              [-147.3348, 59.8339],
              [-147.6872, 59.7417],
              [-147.9564, 59.7441],
              [-148.2185, 59.8756],
              [-148.9802, 59.8937],
              [-149.2696, 59.8454],
              [-149.4675, 59.7338],
              [-149.6213, 59.5275],
              [-150.4565, 59.3087],
              [-150.9922, 59.1808],
              [-151.4911, 59.0959],
              [-153.0212, 59.0025],
              [-153.1841, 58.9057],
              [-153.1669, 58.7552],
              [-153.4788, 58.6417],
              [-153.5152, 58.5484],
              [-153.8818, 58.4565],
              [-154.1196, 58.1132],
              [-154.3907, 57.9993],
              [-154.9407, 57.9751],
              [-154.994, 57.8632],
              [-155.3004, 57.6429],
              [-155.6326, 57.5868],
              [-155.9398, 57.4129],
              [-156.1672, 57.3458],
              [-156.2278, 57.1624],
              [-156.359, 57.0372],
              [-156.3573, 56.8223],
              [-156.7428, 56.578],
              [-157.7119, 55.9694],
              [-158.2374, 55.635],
              [-159.458, 55.6289],
              [-159.5382, 55.532],
              [-159.9511, 55.5076],
              [-160.0412, 55.4489],
              [-159.6602, 55.2429],
              [-159.5443, 55.3284],
              [-159.3981, 55.2934],
              [-159.4109, 55.1235],
              [-159.2528, 55.1062],
              [-159.2515, 55.0067],
              [-159.1118, 54.9157],
              [-159.2015, 54.8337],
              [-159.3623, 54.8283],
              [-159.5803, 54.9876],
              [-159.7347, 54.9902],
              [-159.8461, 54.9024],
              [-160.098, 54.8854],
              [-160.3028, 54.8297],
              [-160.3434, 54.9309],
              [-160.2619, 55.0352],
              [-160.3536, 55.1936],
              [-160.5195, 55.0251],
              [-160.5987, 55.0941],
              [-160.8997, 55.0954],
              [-160.9534, 55.3254],
              [-161.4701, 54.9497],
              [-161.6595, 54.9564],
              [-161.5647, 54.7972],
              [-161.841, 54.6845],
              [-161.9668, 54.7148],
              [-162.1048, 54.6183],
              [-162.2394, 54.6387],
              [-162.365, 54.5077],
              [-162.4798, 54.5409],
              [-162.4599, 54.6665],
              [-162.6511, 54.9222],
              [-163.1049, 54.8094],
              [-162.9686, 54.7087],
              [-163.0591, 54.6062],
              [-163.4139, 54.6004],
              [-163.5936, 54.5411],
              [-163.7128, 54.5702],
              [-164.1227, 54.5561],
              [-164.415, 54.3688],
              [-164.6171, 54.3389],
              [-164.9013, 54.3747],
              [-164.9956, 54.4573],
              [-164.9995, 54.6262],
              [-164.7828, 54.6917],
              [-164.6143, 54.9239],
              [-164.4181, 54.9796],
              [-164.3075, 54.9515],
              [-163.9502, 55.0788],
              [-163.6307, 55.0932],
              [-163.1505, 55.2295],
              [-162.9352, 55.382],
              [-162.5472, 55.5288],
              [-162.2898, 55.7249],
              [-161.8257, 55.9427],
              [-161.2111, 56.0674],
              [-160.616, 56.0674],
              [-160.4132, 56.3217],
              [-159.9051, 56.5782],
              [-159.1739, 56.8145],
              [-158.8934, 56.9451],
              [-158.6677, 57.2957],
              [-158.4874, 57.6156],
              [-158.0628, 58.4855],
              [-158.213, 58.5426],
              [-158.6052, 58.4556],
              [-158.8103, 58.3471],
              [-159.0187, 58.3341],
              [-159.1514, 58.4158],
              [-159.5447, 58.7784],
              [-159.7017, 58.7964],
              [-159.8939, 58.715],
              [-160.2976, 58.7991],
              [-160.3431, 58.8805],
              [-160.6016, 58.7933],
              [-160.8018, 58.5514],
              [-160.9334, 58.4961],
              [-161.1589, 58.5124],
              [-161.234, 58.6435],
              [-161.6403, 58.5023],
              [-161.865, 58.5661],
              [-162.2303, 58.5986],
              [-162.1685, 58.7103],
              [-161.963, 58.7262],
              [-161.8512, 58.808],
              [-161.8818, 58.9442],
              [-162.1022, 59.1287],
              [-162.1666, 59.2751],
              [-162.0536, 59.409],
              [-161.8713, 59.5248],
              [-162.1783, 59.8148],
              [-162.3643, 59.8109],
              [-162.4499, 59.8846],
              [-162.7076, 59.8802],
              [-162.8687, 59.7804],
              [-163.1459, 59.7956],
              [-163.7844, 59.7406],
              [-163.761, 59.6397],
              [-164.0133, 59.6378],
              [-164.4157, 59.8849],
              [-164.3643, 59.9992],
              [-164.7488, 60.2403],
              [-164.9719, 60.277],
              [-165.2354, 60.4252],
              [-165.4265, 60.4744],
              [-165.5183, 60.566],
              [-165.1234, 60.7366],
              [-165.1705, 60.8769],
              [-165.3466, 61.0154],
              [-165.6636, 61.0578],
              [-165.7869, 61.1981],
              [-166.1945, 61.4599],
              [-166.2886, 61.5808],
              [-166.1978, 61.8343],
              [-166.1125, 61.8731],
              [-166.0492, 62.108],
              [-165.7396, 62.2049],
              [-165.4489, 62.391],
              [-165.2219, 62.7225],
              [-164.8955, 62.9486],
              [-164.9067, 63.0449],
              [-164.4957, 63.2512],
              [-164.0513, 63.3173],
              [-163.6581, 63.2497],
              [-163.2525, 63.0924],
              [-163.1187, 63.1016],
              [-162.8709, 63.2536],
              [-162.7395, 63.2689],
              [-162.4378, 63.492],
              [-162.6798, 63.4916],
              [-162.8264, 63.5764],
              [-162.5843, 63.6914],
              [-162.3944, 63.6883],
              [-162.2227, 63.5969],
              [-161.957, 63.5572],
              [-161.8404, 63.4933],
              [-161.3144, 63.5192],
              [-160.8862, 63.7714],
              [-161.0457, 64.0225],
              [-161.0754, 64.2066],
              [-161.4139, 64.3586],
              [-161.5908, 64.3355],
              [-162.3135, 64.5428],
              [-162.5179, 64.3849],
              [-162.7958, 64.2757],
              [-163.2419, 64.3607],
              [-163.5362, 64.5036],
              [-163.8141, 64.5312],
              [-164.3554, 64.5084],
              [-164.7548, 64.4078],
              [-165.0322, 64.388],
              [-165.3359, 64.4444],
              [-166.2814, 64.545],
              [-166.5078, 64.6314],
              [-166.5902, 64.739],
              [-166.5892, 64.9083],
              [-166.979, 65.0694],
              [-167.0843, 65.1557],
              [-167.0703, 65.3275],
              [-167.4009, 65.3473],
              [-168.1676, 65.5469],
              [-168.1551, 65.7284],
              [-167.5208, 65.8782],
              [-166.7195, 66.148],
              [-165.4344, 66.4752],
              [-164.4243, 66.6302],
              [-163.6262, 66.6242],
              [-162.8321, 66.9268],
            ],
          ],
          [
            [
              [-167.5054, 60.157],
              [-167.5081, 60.2631],
              [-167.3098, 60.2907],
              [-166.9438, 60.2781],
              [-166.7393, 60.3879],
              [-166.5288, 60.4443],
              [-166.3498, 60.4177],
              [-166.1524, 60.5108],
              [-166.0058, 60.4196],
              [-165.7385, 60.4056],
              [-165.5615, 60.3017],
              [-165.5732, 60.0751],
              [-165.4025, 59.9704],
              [-165.4955, 59.8702],
              [-165.8249, 59.8068],
              [-166.0579, 59.6955],
              [-166.1966, 59.7034],
              [-166.4297, 59.8016],
              [-166.6261, 59.7971],
              [-166.9406, 59.9119],
              [-167.3856, 60.0212],
              [-167.5054, 60.157],
            ],
          ],
        ],
      },
      properties: { STATE_ID: '02', STATE_NAME: 'Alaska' },
      id: 2,
    },
    {
      type: 'Feature',
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-170.7972, -14.2466],
              [-170.6871, -14.1822],
              [-170.5368, -14.2043],
              [-170.5094, -14.3288],
              [-170.6428, -14.3403],
              [-170.7258, -14.4159],
              [-170.8837, -14.3427],
              [-170.7972, -14.2466],
            ],
          ],
          [
            [
              [-169.6298, -14.2295],
              [-169.6615, -14.1089],
              [-169.5061, -14.1669],
              [-169.3952, -14.17],
              [-169.3802, -14.2948],
              [-169.5134, -14.3078],
              [-169.6298, -14.2295],
            ],
          ],
        ],
      },
      properties: { STATE_ID: '60', STATE_NAME: 'American Samoa' },
      id: 60,
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-89.9571, 47.2911],
            [-89.4834, 48.0137],
            [-89.3375, 47.9743],
            [-88.6774, 48.2454],
            [-88.3699, 48.3061],
            [-87.2501, 47.8705],
            [-86.1251, 47.4167],
            [-84.8593, 46.8888],
            [-84.763, 46.6343],
            [-84.5569, 46.4606],
            [-84.4203, 46.5011],
            [-84.1112, 46.5042],
            [-84.1462, 46.4185],
            [-84.1081, 46.2412],
            [-83.9554, 46.0572],
            [-83.8262, 46.1193],
            [-83.5715, 46.106],
            [-83.4336, 45.9989],
            [-83.6369, 45.7739],
            [-83.484, 45.7719],
            [-82.5206, 45.3359],
            [-82.3564, 44.625],
            [-82.123, 43.5908],
            [-82.4556, 42.9269],
            [-82.4677, 42.7619],
            [-82.5248, 42.606],
            [-82.6431, 42.5543],
            [-82.8303, 42.3735],
            [-83.0636, 42.3169],
            [-83.1264, 42.2384],
            [-83.1495, 42.0403],
            [-83.1125, 41.9594],
            [-83.4159, 41.7338],
            [-84.806, 41.6961],
            [-84.8059, 41.7602],
            [-85.6071, 41.7591],
            [-86.6026, 41.7595],
            [-87.2078, 41.761],
            [-87.0199, 42.4935],
            [-87.1472, 43.3799],
            [-87.1141, 43.7341],
            [-87.0132, 44.1319],
            [-86.6863, 44.881],
            [-86.4999, 45.0808],
            [-86.2495, 45.2361],
            [-86.7565, 45.4436],
            [-87.1011, 45.4442],
            [-87.1712, 45.3525],
            [-87.4057, 45.202],
            [-87.4428, 45.0765],
            [-87.6612, 45.1083],
            [-87.7352, 45.1771],
            [-87.6481, 45.3394],
            [-87.8504, 45.3475],
            [-87.8553, 45.4414],
            [-87.7747, 45.602],
            [-87.8798, 45.7548],
            [-88.0714, 45.7799],
            [-88.1488, 45.9376],
            [-88.5155, 46.0201],
            [-88.6165, 45.9877],
            [-88.7821, 46.0162],
            [-89.0916, 46.1384],
            [-90.1206, 46.337],
            [-90.2162, 46.5013],
            [-90.4184, 46.5661],
            [-89.9571, 47.2911],
          ],
        ],
      },
      properties: { STATE_ID: '26', STATE_NAME: 'Michigan' },
      id: 26,
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-94.6179, 36.4994],
            [-93.1617, 36.498],
            [-92.3752, 36.4972],
            [-91.4221, 36.4971],
            [-90.1523, 36.498],
            [-90.0632, 36.3836],
            [-90.0838, 36.2719],
            [-90.2196, 36.1848],
            [-90.3779, 35.9957],
            [-89.7331, 36.0006],
            [-89.7656, 35.8914],
            [-89.7057, 35.8185],
            [-89.954, 35.7362],
            [-89.9408, 35.5561],
            [-90.0396, 35.5486],
            [-90.1295, 35.4419],
            [-90.075, 35.3841],
            [-90.1521, 35.256],
            [-90.0666, 35.136],
            [-90.1736, 35.1181],
            [-90.2094, 35.0266],
            [-90.3093, 34.9957],
            [-90.2501, 34.9073],
            [-90.5221, 34.7546],
            [-90.4797, 34.6599],
            [-90.5872, 34.6157],
            [-90.5457, 34.5378],
            [-90.5807, 34.4106],
            [-90.8316, 34.2696],
            [-90.9543, 34.1385],
            [-90.8969, 34.0246],
            [-91.0115, 33.9247],
            [-91.0425, 33.8128],
            [-91.219, 33.6615],
            [-91.085, 33.1593],
            [-91.1661, 33.0041],
            [-91.9555, 33.0075],
            [-93.0002, 33.0176],
            [-94.043, 33.0192],
            [-94.0434, 33.5521],
            [-94.3895, 33.5467],
            [-94.4859, 33.6379],
            [-94.4661, 34.3481],
            [-94.431, 35.3925],
            [-94.6179, 36.4994],
          ],
        ],
      },
      properties: { STATE_ID: '05', STATE_NAME: 'Arkansas' },
      id: 5,
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-90.5221, 34.7546],
            [-90.2501, 34.9073],
            [-90.3093, 34.9957],
            [-88.9252, 34.9948],
            [-88.2001, 34.9956],
            [-88.0979, 34.8921],
            [-88.2107, 34.0292],
            [-88.2796, 33.4941],
            [-88.4038, 32.4488],
            [-88.4731, 31.896],
            [-88.4487, 31.4207],
            [-88.3844, 30.1585],
            [-88.4613, 30.1467],
            [-88.7782, 30.1933],
            [-88.889, 30.1398],
            [-89.0965, 30.1658],
            [-89.1837, 30.2122],
            [-89.2884, 30.1785],
            [-89.5719, 30.1807],
            [-89.647, 30.2918],
            [-89.6836, 30.4518],
            [-89.8058, 30.5674],
            [-89.8313, 30.7676],
            [-89.7526, 31.0019],
            [-91.0602, 30.9989],
            [-91.6366, 30.9994],
            [-91.5902, 31.1756],
            [-91.6412, 31.2669],
            [-91.549, 31.3472],
            [-91.4869, 31.5867],
            [-91.3961, 31.6393],
            [-91.3452, 31.8439],
            [-91.201, 31.9092],
            [-91.0386, 32.0983],
            [-91.1649, 32.1957],
            [-90.9827, 32.2152],
            [-90.9867, 32.3518],
            [-91.0935, 32.4574],
            [-91.1527, 32.6408],
            [-91.1436, 32.8447],
            [-91.2128, 32.9221],
            [-91.1661, 33.0041],
            [-91.085, 33.1593],
            [-91.219, 33.6615],
            [-91.0425, 33.8128],
            [-91.0115, 33.9247],
            [-90.8969, 34.0246],
            [-90.9543, 34.1385],
            [-90.8316, 34.2696],
            [-90.5807, 34.4106],
            [-90.5457, 34.5378],
            [-90.5872, 34.6157],
            [-90.4797, 34.6599],
            [-90.5221, 34.7546],
          ],
        ],
      },
      properties: { STATE_ID: '28', STATE_NAME: 'Mississippi' },
      id: 28,
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-95.3083, 40],
            [-95.4216, 40.0577],
            [-95.3987, 40.1264],
            [-95.4787, 40.2437],
            [-95.6104, 40.314],
            [-95.7656, 40.5852],
            [-94.5523, 40.5708],
            [-93.2586, 40.5808],
            [-92.6252, 40.591],
            [-91.7291, 40.6136],
            [-91.4834, 40.3827],
            [-91.4194, 40.3783],
            [-91.5065, 40.2363],
            [-91.495, 40.0369],
            [-91.4194, 39.9277],
            [-91.4338, 39.8419],
            [-91.3678, 39.729],
            [-91.0383, 39.4484],
            [-90.727, 39.2512],
            [-90.663, 38.9263],
            [-90.5776, 38.8684],
            [-90.4717, 38.9592],
            [-90.2502, 38.9193],
            [-90.1133, 38.8493],
            [-90.213, 38.712],
            [-90.1794, 38.6267],
            [-90.3708, 38.3336],
            [-90.3532, 38.2129],
            [-90.2426, 38.1121],
            [-90.0595, 38.0156],
            [-89.8985, 37.8709],
            [-89.7961, 37.8595],
            [-89.6679, 37.7586],
            [-89.512, 37.6855],
            [-89.5165, 37.5356],
            [-89.4209, 37.3939],
            [-89.5185, 37.2863],
            [-89.3796, 37.0407],
            [-89.2921, 36.9922],
            [-89.1329, 36.9821],
            [-89.1171, 36.8887],
            [-89.1993, 36.7156],
            [-89.2271, 36.5695],
            [-89.3642, 36.6259],
            [-89.4173, 36.499],
            [-89.4854, 36.4975],
            [-89.5392, 36.4979],
            [-89.5364, 36.2728],
            [-89.6065, 36.2383],
            [-89.5935, 36.1281],
            [-89.7331, 36.0006],
            [-90.3779, 35.9957],
            [-90.2196, 36.1848],
            [-90.0838, 36.2719],
            [-90.0632, 36.3836],
            [-90.1523, 36.498],
            [-91.4221, 36.4971],
            [-92.3752, 36.4972],
            [-93.1617, 36.498],
            [-94.6179, 36.4994],
            [-94.618, 36.9989],
            [-94.6176, 37.7807],
            [-94.6134, 38.458],
            [-94.6073, 39.1134],
            [-94.8317, 39.2159],
            [-94.9902, 39.4462],
            [-95.1081, 39.5399],
            [-94.8837, 39.8317],
            [-94.9533, 39.901],
            [-95.0902, 39.8631],
            [-95.3083, 40],
          ],
        ],
      },
      properties: { STATE_ID: '29', STATE_NAME: 'Missouri' },
      id: 29,
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-116.0491, 49.0008],
            [-115.2754, 48.9997],
            [-113.6843, 48.9975],
            [-113.1155, 48.9983],
            [-111.5884, 48.9967],
            [-110.567, 48.9988],
            [-109.7412, 48.9999],
            [-108.389, 48.9993],
            [-107.4156, 48.9995],
            [-105.9899, 48.9994],
            [-104.7941, 48.9987],
            [-104.0487, 48.9996],
            [-104.0414, 47.8631],
            [-104.0452, 46.5827],
            [-104.0456, 45.9453],
            [-104.0397, 44.9981],
            [-104.0579, 44.9976],
            [-104.5919, 44.9986],
            [-105.9132, 45.0002],
            [-106.5974, 44.9948],
            [-108.3754, 44.9999],
            [-109.1032, 45.0058],
            [-110.7045, 44.9922],
            [-111.0546, 45.001],
            [-111.0524, 44.4784],
            [-111.2322, 44.5852],
            [-111.338, 44.7329],
            [-111.4685, 44.6787],
            [-111.5923, 44.5611],
            [-112.0963, 44.5231],
            [-112.2883, 44.5681],
            [-112.4734, 44.4794],
            [-112.7802, 44.4822],
            [-112.8856, 44.3963],
            [-113.0019, 44.4498],
            [-113.1016, 44.7295],
            [-113.2469, 44.823],
            [-113.341, 44.7846],
            [-113.4551, 44.8654],
            [-113.452, 45.0592],
            [-113.5767, 45.1312],
            [-113.7387, 45.3294],
            [-113.8342, 45.5231],
            [-113.8064, 45.6016],
            [-114.0137, 45.6583],
            [-114.33, 45.4597],
            [-114.472, 45.5652],
            [-114.5663, 45.7727],
            [-114.3872, 45.8885],
            [-114.4934, 46.0526],
            [-114.4452, 46.1679],
            [-114.4708, 46.2654],
            [-114.3719, 46.4425],
            [-114.3208, 46.6471],
            [-114.6131, 46.6385],
            [-114.6209, 46.7073],
            [-114.9223, 46.8286],
            [-115.3235, 47.2563],
            [-115.5285, 47.2992],
            [-115.7545, 47.5525],
            [-115.7227, 47.6949],
            [-115.8246, 47.7524],
            [-116.0491, 47.9768],
            [-116.0491, 49.0008],
          ],
        ],
      },
      properties: { STATE_ID: '30', STATE_NAME: 'Montana' },
      id: 30,
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-102.0517, 40.0031],
            [-100.4956, 40.0017],
            [-99.3098, 40.0018],
            [-98.5003, 40.0022],
            [-97.4634, 40.002],
            [-96.6204, 40.001],
            [-95.3083, 40],
            [-95.0902, 39.8631],
            [-94.9533, 39.901],
            [-94.8837, 39.8317],
            [-95.1081, 39.5399],
            [-94.9902, 39.4462],
            [-94.8317, 39.2159],
            [-94.6073, 39.1134],
            [-94.6134, 38.458],
            [-94.6176, 37.7807],
            [-94.618, 36.9989],
            [-95.381, 36.9993],
            [-96.5001, 36.9986],
            [-97.4899, 36.9986],
            [-98.31, 36.9977],
            [-99.6477, 37],
            [-101.1254, 36.9975],
            [-102.0421, 36.993],
            [-102.0416, 37.6967],
            [-102.0453, 38.8569],
            [-102.0517, 40.0031],
          ],
        ],
      },
      properties: { STATE_ID: '20', STATE_NAME: 'Kansas' },
      id: 20,
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-87.2078, 41.761],
            [-86.6026, 41.7595],
            [-85.6071, 41.7591],
            [-84.8059, 41.7602],
            [-84.806, 41.6961],
            [-84.8032, 40.959],
            [-84.8046, 40.2522],
            [-84.8202, 39.1055],
            [-84.8125, 38.7867],
            [-84.9919, 38.7785],
            [-85.1731, 38.688],
            [-85.2893, 38.7424],
            [-85.4354, 38.7291],
            [-85.416, 38.5637],
            [-85.4931, 38.4729],
            [-85.6183, 38.4263],
            [-85.6554, 38.3239],
            [-85.8195, 38.2819],
            [-85.9041, 38.1734],
            [-85.9471, 38.0051],
            [-86.1789, 38.0108],
            [-86.3259, 38.154],
            [-86.525, 38.0289],
            [-86.5067, 37.9306],
            [-86.7523, 37.9148],
            [-86.7949, 37.9892],
            [-87.0459, 37.8927],
            [-87.1326, 37.7901],
            [-87.3854, 37.9377],
            [-87.833, 37.8771],
            [-87.9078, 37.8068],
            [-88.028, 37.7992],
            [-88.095, 37.8937],
            [-88.037, 37.9563],
            [-87.985, 38.228],
            [-87.8335, 38.3196],
            [-87.7562, 38.4625],
            [-87.4959, 38.7854],
            [-87.5134, 38.956],
            [-87.6587, 39.136],
            [-87.5857, 39.2004],
            [-87.5316, 39.3479],
            [-87.5263, 40.492],
            [-87.5237, 41.7599],
            [-87.2078, 41.761],
          ],
        ],
      },
      properties: { STATE_ID: '18', STATE_NAME: 'Indiana' },
      id: 18,
    },
    {
      type: 'Feature',
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-67.9967, 18.1351],
              [-67.9737, 18.2036],
              [-67.792, 18.1075],
              [-67.8224, 18.0264],
              [-67.9761, 18.0396],
              [-67.9967, 18.1351],
            ],
          ],
          [
            [
              [-67.2079, 18.4423],
              [-67.1605, 18.5525],
              [-67.0171, 18.5626],
              [-66.7346, 18.526],
              [-66.416, 18.5436],
              [-65.9934, 18.5166],
              [-65.6246, 18.4513],
              [-65.464, 18.3935],
              [-65.3626, 18.4195],
              [-65.1818, 18.3577],
              [-65.1911, 18.2589],
              [-65.2867, 18.226],
              [-65.2496, 18.0843],
              [-65.5497, 18.0284],
              [-65.7161, 18.1063],
              [-65.8525, 17.9415],
              [-66.0767, 17.9107],
              [-66.2433, 17.8626],
              [-66.4246, 17.8819],
              [-66.5133, 17.8354],
              [-66.5811, 17.9022],
              [-66.7882, 17.9161],
              [-66.9178, 17.8755],
              [-67.2587, 17.9252],
              [-67.2602, 18.0848],
              [-67.2152, 18.2119],
              [-67.3199, 18.3432],
              [-67.2079, 18.4423],
            ],
          ],
        ],
      },
      properties: { STATE_ID: '72', STATE_NAME: 'Puerto Rico' },
      id: 72,
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-96.5637, 45.9352],
            [-96.5831, 45.82],
            [-96.8568, 45.6054],
            [-96.6806, 45.4105],
            [-96.4891, 45.3571],
            [-96.4531, 45.3008],
            [-96.453, 44.1379],
            [-96.4533, 43.5004],
            [-96.5242, 43.3947],
            [-96.5536, 43.227],
            [-96.4365, 43.12],
            [-96.5976, 42.7918],
            [-96.6049, 42.7015],
            [-96.5157, 42.6298],
            [-96.4455, 42.4906],
            [-96.6035, 42.5045],
            [-96.8062, 42.7041],
            [-97.1303, 42.7715],
            [-97.2207, 42.8468],
            [-97.6865, 42.8422],
            [-97.8755, 42.8587],
            [-97.9472, 42.7707],
            [-98.4674, 42.9476],
            [-98.4985, 42.9986],
            [-99.8406, 42.9979],
            [-100.8875, 42.998],
            [-101.633, 42.9963],
            [-102.8679, 43],
            [-104.053, 43.0006],
            [-104.0545, 44.1804],
            [-104.0579, 44.9976],
            [-104.0397, 44.9981],
            [-104.0456, 45.9453],
            [-102.9647, 45.945],
            [-102.0196, 45.9445],
            [-100.6054, 45.9436],
            [-99.846, 45.9411],
            [-98.3115, 45.9361],
            [-97.4795, 45.9351],
            [-96.5637, 45.9352],
          ],
        ],
      },
      properties: { STATE_ID: '46', STATE_NAME: 'South Dakota' },
      id: 46,
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-71.7992, 42.008],
            [-71.8007, 42.0235],
            [-73.0441, 42.0395],
            [-73.4873, 42.0495],
            [-73.5082, 42.0861],
            [-73.2648, 42.7458],
            [-72.4584, 42.7269],
            [-71.2946, 42.697],
            [-71.1328, 42.8215],
            [-70.9296, 42.885],
            [-70.735, 42.8747],
            [-70.7026, 42.7242],
            [-70.5493, 42.7242],
            [-70.5021, 42.6246],
            [-70.6063, 42.5312],
            [-70.6904, 42.279],
            [-70.6084, 42.1385],
            [-70.2774, 42.1144],
            [-70.1725, 42.1345],
            [-70.0116, 42.0734],
            [-69.9291, 41.9785],
            [-69.8665, 41.817],
            [-69.8629, 41.6731],
            [-69.9466, 41.5121],
            [-70.1144, 41.4841],
            [-70.1604, 41.5994],
            [-70.3952, 41.5567],
            [-70.5042, 41.4949],
            [-70.3236, 41.3916],
            [-70.1487, 41.3452],
            [-70.0065, 41.4339],
            [-69.9101, 41.3173],
            [-69.9103, 41.2268],
            [-70.1097, 41.1898],
            [-70.3441, 41.2762],
            [-70.685, 41.2932],
            [-70.8059, 41.1987],
            [-70.8773, 41.2241],
            [-70.9026, 41.3676],
            [-70.9908, 41.3513],
            [-71.0886, 41.4313],
            [-71.1326, 41.6604],
            [-71.3285, 41.7807],
            [-71.3814, 42.0188],
            [-71.7992, 42.008],
          ],
        ],
      },
      properties: { STATE_ID: '25', STATE_NAME: 'Massachusetts' },
      id: 25,
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-77.7195, 39.3213],
            [-77.5683, 39.3065],
            [-77.475, 39.2057],
            [-77.4751, 39.2055],
            [-77.475, 39.2057],
            [-77.4807, 39.1884],
            [-77.4985, 39.1137],
            [-77.2987, 39.0486],
            [-77.2987, 39.0485],
            [-77.1958, 38.9675],
            [-77.1834, 38.9684],
            [-77.1785, 38.9681],
            [-77.1574, 38.9659],
            [-77.1398, 38.9576],
            [-77.1198, 38.9343],
            [-77.1154, 38.9277],
            [-77.1149, 38.9273],
            [-77.1137, 38.9258],
            [-77.1136, 38.9258],
            [-77.1136, 38.9257],
            [-77.1136, 38.9258],
            [-77.1136, 38.9257],
            [-77.1129, 38.925],
            [-77.1128, 38.925],
            [-77.1129, 38.925],
            [-77.1128, 38.925],
            [-77.1128, 38.9249],
            [-77.1123, 38.9244],
            [-77.1073, 38.9203],
            [-77.1066, 38.9195],
            [-77.1052, 38.9173],
            [-77.0675, 38.8981],
            [-77.0659, 38.8946],
            [-77.0339, 38.8426],
            [-77.0434, 38.8405],
            [-77.0454, 38.8379],
            [-77.0382, 38.8277],
            [-77.0386, 38.8171],
            [-77.039, 38.7916],
            [-77.0436, 38.7191],
            [-77.0742, 38.712],
            [-77.0742, 38.7128],
            [-77.0774, 38.7134],
            [-77.0963, 38.7004],
            [-77.3185, 38.4744],
            [-77.2352, 38.3325],
            [-77.0307, 38.3116],
            [-76.8791, 38.1741],
            [-76.6104, 38.1485],
            [-76.5166, 38.0268],
            [-76.2334, 37.8876],
            [-76.052, 37.9536],
            [-75.9527, 37.9068],
            [-75.8012, 37.9122],
            [-75.6244, 37.9942],
            [-75.1664, 38.0278],
            [-75.296, 37.8458],
            [-75.4171, 37.821],
            [-75.5418, 37.6061],
            [-75.5299, 37.5567],
            [-75.7446, 37.1823],
            [-75.8897, 37.0545],
            [-75.9269, 36.9365],
            [-75.7975, 36.5509],
            [-76.7694, 36.5508],
            [-77.4492, 36.5448],
            [-78.5116, 36.541],
            [-79.5531, 36.5409],
            [-80.2949, 36.544],
            [-80.838, 36.5591],
            [-81.6775, 36.5881],
            [-83.2613, 36.5939],
            [-83.6754, 36.6008],
            [-83.1942, 36.7395],
            [-83.0728, 36.8545],
            [-82.8791, 36.8892],
            [-82.871, 36.9688],
            [-82.7428, 37.0428],
            [-82.7214, 37.1208],
            [-82.3486, 37.268],
            [-81.968, 37.538],
            [-81.9966, 37.4767],
            [-81.8535, 37.2877],
            [-81.6782, 37.2015],
            [-81.5613, 37.2066],
            [-81.3627, 37.3381],
            [-81.2251, 37.2349],
            [-80.9005, 37.315],
            [-80.552, 37.4736],
            [-80.4756, 37.423],
            [-80.2998, 37.5083],
            [-80.2589, 37.5955],
            [-80.2961, 37.6917],
            [-80.1622, 37.8751],
            [-80.0091, 37.9907],
            [-79.9163, 38.1864],
            [-79.7889, 38.2687],
            [-79.6897, 38.4315],
            [-79.6491, 38.5915],
            [-79.4998, 38.4977],
            [-79.3127, 38.412],
            [-79.2106, 38.4929],
            [-78.994, 38.8501],
            [-78.8693, 38.763],
            [-78.788, 38.8851],
            [-78.6805, 38.9253],
            [-78.4039, 39.1677],
            [-78.4194, 39.2575],
            [-78.3404, 39.3536],
            [-78.347, 39.466],
            [-77.8283, 39.1325],
            [-77.7195, 39.3213],
          ],
        ],
      },
      properties: { STATE_ID: '51', STATE_NAME: 'Virginia' },
      id: 51,
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-77.039, 38.7916],
            [-77.0386, 38.8171],
            [-77.0382, 38.8277],
            [-77.0454, 38.8379],
            [-77.0434, 38.8405],
            [-77.0339, 38.8426],
            [-77.0659, 38.8946],
            [-77.0675, 38.8981],
            [-77.1052, 38.9173],
            [-77.1066, 38.9195],
            [-77.1073, 38.9203],
            [-77.1123, 38.9244],
            [-77.1128, 38.9249],
            [-77.1128, 38.925],
            [-77.1129, 38.925],
            [-77.1128, 38.925],
            [-77.1129, 38.925],
            [-77.1136, 38.9257],
            [-77.1136, 38.9258],
            [-77.1136, 38.9257],
            [-77.1136, 38.9258],
            [-77.1137, 38.9258],
            [-77.1149, 38.9273],
            [-77.1154, 38.9277],
            [-77.1198, 38.9343],
            [-77.0409, 38.9958],
            [-76.9096, 38.8927],
            [-77.039, 38.7916],
          ],
        ],
      },
      properties: { STATE_ID: '11', STATE_NAME: 'District of Columbia' },
      id: 11,
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-96.4533, 43.5004],
            [-95.7907, 43.4999],
            [-94.2771, 43.5001],
            [-93.0724, 43.4996],
            [-92.0346, 43.5007],
            [-91.2177, 43.5005],
            [-91.2018, 43.3491],
            [-91.0579, 43.254],
            [-91.1753, 43.1347],
            [-91.1435, 42.9047],
            [-91.0638, 42.7506],
            [-90.9763, 42.696],
            [-90.7202, 42.6408],
            [-90.6428, 42.5085],
            [-90.4779, 42.3841],
            [-90.3911, 42.2255],
            [-90.2095, 42.1527],
            [-90.1412, 42.0089],
            [-90.188, 41.8032],
            [-90.3156, 41.7344],
            [-90.3432, 41.5878],
            [-90.6558, 41.4621],
            [-90.8576, 41.4528],
            [-91.0483, 41.4098],
            [-91.1142, 41.25],
            [-90.9463, 41.0945],
            [-90.9629, 40.925],
            [-91.0901, 40.8246],
            [-91.1239, 40.6692],
            [-91.3487, 40.6097],
            [-91.3757, 40.3919],
            [-91.4194, 40.3783],
            [-91.4834, 40.3827],
            [-91.7291, 40.6136],
            [-92.6252, 40.591],
            [-93.2586, 40.5808],
            [-94.5523, 40.5708],
            [-95.7656, 40.5852],
            [-95.8832, 40.7176],
            [-95.8121, 40.8842],
            [-95.9517, 41.3375],
            [-95.9228, 41.4573],
            [-96.0919, 41.5341],
            [-96.121, 41.677],
            [-96.0648, 41.7944],
            [-96.2334, 42.0397],
            [-96.2338, 42.0398],
            [-96.234, 42.0399],
            [-96.2348, 42.0401],
            [-96.234, 42.0399],
            [-96.2338, 42.0398],
            [-96.2334, 42.0397],
            [-96.3457, 42.1633],
            [-96.3238, 42.2299],
            [-96.408, 42.3374],
            [-96.4455, 42.4906],
            [-96.5157, 42.6298],
            [-96.6049, 42.7015],
            [-96.5976, 42.7918],
            [-96.4365, 43.12],
            [-96.5536, 43.227],
            [-96.5242, 43.3947],
            [-96.4533, 43.5004],
          ],
        ],
      },
      properties: { STATE_ID: '19', STATE_NAME: 'Iowa' },
      id: 19,
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-114.6335, 35.0019],
            [-114.643, 35.0966],
            [-114.5695, 35.1623],
            [-114.5956, 35.3265],
            [-114.6789, 35.5013],
            [-114.6955, 35.8299],
            [-114.7547, 36.0851],
            [-114.6271, 36.1408],
            [-114.3702, 36.1426],
            [-114.2439, 36.0153],
            [-114.1534, 36.0232],
            [-114.044, 36.194],
            [-114.05, 37.0002],
            [-112.9961, 37.0001],
            [-111.9654, 37.001],
            [-110.4697, 36.9977],
            [-109.0452, 36.999],
            [-109.0461, 36.0515],
            [-109.0469, 35.3795],
            [-109.046, 34.5901],
            [-109.0467, 33.7612],
            [-109.0477, 32.7934],
            [-109.0486, 32.1718],
            [-109.05, 31.3325],
            [-109.8756, 31.3341],
            [-111.0748, 31.3322],
            [-112.3653, 31.7411],
            [-113.126, 31.9728],
            [-113.9337, 32.2259],
            [-114.8136, 32.4943],
            [-114.8096, 32.6162],
            [-114.7196, 32.7188],
            [-114.5269, 32.7571],
            [-114.4629, 32.9079],
            [-114.5201, 33.03],
            [-114.6708, 33.038],
            [-114.7079, 33.0974],
            [-114.6745, 33.2556],
            [-114.7253, 33.4023],
            [-114.5242, 33.5531],
            [-114.4965, 33.6969],
            [-114.5349, 33.9257],
            [-114.4117, 34.11],
            [-114.2297, 34.1869],
            [-114.1383, 34.3032],
            [-114.3408, 34.4515],
            [-114.4357, 34.5938],
            [-114.4716, 34.713],
            [-114.6352, 34.875],
            [-114.6335, 35.0019],
          ],
        ],
      },
      properties: { STATE_ID: '04', STATE_NAME: 'Arizona' },
      id: 4,
    },
  ],
};

export default states;
