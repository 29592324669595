import { classNames } from '../../utils';

interface IButton {
  children: React.ReactNode;
  className?: string;
  type?: 'button' | 'submit';
  styleType?: 'primary' | 'secondary';
  onClick?: (args: any) => void;
}

const defaultStyleClass =
  'px-4 py-2 sm:px-4 sm:py-2 text-gray-900 text-xs sm:text-sm text-white rounded-lg shadow-sm focus:outline-none border-2 border-transparent';

const secondaryClass =
  'px-4 py-2 sm:px-4 sm:py-2 text-gray-900 text-xs sm:text-sm bg-white text-gray rounded-lg shadow-sm focus:outline-none border-2 border-transparent';

const Button = ({
  type = 'button',
  styleType = 'primary',
  className = '',
  children,
  onClick,
}: IButton) => {
  return (
    <button
      className={classNames(defaultStyleClass, className, styleType)}
      type={type}
      onClick={onClick}
    >
      {children}
    </button>
  );
};

export default Button;
export { defaultStyleClass, secondaryClass };
