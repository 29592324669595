import { Dialog, Transition } from '@headlessui/react';
import { Fragment, useEffect, useRef, useState } from 'react';

import { Component as Button } from './components/Buttton';
import { Component as Map } from './components/Map';
import { Component as MenuDropdown } from './components/MenuDropdown';
import { Component as PrintContentView } from './components/PrintContentView';
import { Component as SidebarMenu } from './components/SidebarMenu';
import { Component as SliderOut } from './components/SliderOut';
import { Component as Welcome } from './components/Welcome';
import { XIcon } from '@heroicons/react/outline';
import { isMobile } from 'react-device-detect';
import logo from './images/logo.svg';
import mapBG from './images/map-bg.jpg';
import { useLocation } from 'react-router-dom';

const usePrevLocation = (location: any) => {
  const prevLocRef = useRef(location);

  useEffect(() => {
    prevLocRef.current = location;
  }, [location]);

  return prevLocRef.current;
};

export default function Main() {
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const location = useLocation();
  const prevLocation = usePrevLocation(location);

  const callToActionBackToMainSite = () => {
    window.location.replace('https://survivorspace.org');
  };

  useEffect(() => {
    if (location.pathname !== prevLocation.pathname && isMobile) {
      setSidebarOpen(false);
    }
  }, [location, prevLocation]);

  return (
    <>
      <Welcome />
      <PrintContentView />
      <MenuDropdown />
      <img
        className="h-20 absolute top-8 left-8"
        src={logo}
        alt="SurvivorSpace"
      />
      <div>
        <Transition.Root show={sidebarOpen} as={Fragment}>
          <Dialog
            as="div"
            className="relative z-40 md:hidden"
            onClose={setSidebarOpen}
          >
            <Transition.Child
              as={Fragment}
              enter="transition-opacity ease-linear duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="transition-opacity ease-linear duration-300"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <div className="fixed inset-0 bg-gray-600 bg-opacity-75" />
            </Transition.Child>

            <div className="fixed inset-0 flex z-40">
              <Transition.Child
                as={Fragment}
                enter="transition ease-in-out duration-300 transform"
                enterFrom="-translate-x-full"
                enterTo="translate-x-0"
                leave="transition ease-in-out duration-300 transform"
                leaveFrom="translate-x-0"
                leaveTo="-translate-x-full"
              >
                <Dialog.Panel
                  className="relative flex-1 flex flex-col max-w-xs w-full bg-center bg-cover"
                  style={{
                    backgroundImage: `url(${mapBG})`,
                  }}
                >
                  <Transition.Child
                    as={Fragment}
                    enter="ease-in-out duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in-out duration-300"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                  >
                    <div className="absolute top-0 right-0 -mr-12 pt-2">
                      <button
                        type="button"
                        className="ml-1 flex items-center justify-center h-10 w-10 rounded-full focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white"
                        onClick={() => setSidebarOpen(false)}
                      >
                        <span className="sr-only">Close sidebar</span>
                        <XIcon
                          className="h-6 w-6 text-white"
                          aria-hidden="true"
                        />
                      </button>
                    </div>
                  </Transition.Child>
                  <div className="flex-1 h-0 pt-5 pb-4 overflow-y-auto scrollbar-thin">
                    <div className="flex-shrink-0 flex items-center px-4 ">
                      <img className="h-16" src={logo} alt="SurvivorSpace" />
                    </div>
                    <div>
                      <SidebarMenu />
                    </div>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </Dialog>
        </Transition.Root>
        <SliderOut />
      </div>
      <Map />
      <Button
        onClick={callToActionBackToMainSite}
        className="absolute bottom-10 left-10"
      >
        Back to SurvivorSpace
      </Button>
    </>
  );
}
