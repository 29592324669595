import { useCallback, useEffect, useState } from 'react';
import Loading from './Loading';

interface ImageProps extends React.ImgHTMLAttributes<HTMLImageElement> {
  placeholderImg?: string;
  errorImg?: string;
}

const ImageLoader = ({
  src,
  placeholderImg,
  errorImg,
  ...props
}: ImageProps) => {
  const [loading, setLoading] = useState(true);
  const [imgSrc, setSrc] = useState(placeholderImg || src);

  const onLoad = useCallback(() => {
    setSrc(src);
  }, [src]);

  const onError = useCallback(() => {
    setSrc(errorImg || placeholderImg);
  }, [errorImg, placeholderImg]);

  useEffect(() => {
    const img = new Image();
    img.src = src as string;
    img.addEventListener('load', onLoad);
    img.addEventListener('error', onError);
    setLoading(false);
    return () => {
      img.removeEventListener('load', onLoad);
      img.removeEventListener('error', onError);
    };
  }, [src, onLoad, onError, setLoading]);

  if (loading) {
    return <Loading />;
  }

  return <img {...props} alt={imgSrc} src={imgSrc} />;
};

export default ImageLoader;
