import { atom } from 'recoil';
import { getStateFromLocationPath, IState } from './data';

export const statesInfoState = atom({
  key: 'statesInfo',
  default: [] as IState[],
});

export const selectedInfoState = atom({
  key: 'selectedInfoState',
  default: getStateFromLocationPath(),
});

export const showPrintView = atom({
  key: 'showPrintView',
  default: false,
});
