import { Menu, Transition } from '@headlessui/react';
import { ChevronDownIcon, ChevronUpIcon } from '@heroicons/react/outline';
import { Fragment, useCallback, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useRecoilState, useRecoilValue } from 'recoil';
import { selectedInfoState, statesInfoState } from '../../atoms';
import { defaultCountry, IState } from '../../data';
import { secondaryClass } from '../Buttton';

function classNames(...classes: any[]) {
  return classes.filter(Boolean).join(' ');
}

export default function MenuDropdown() {
  const [toggle, setToggle] = useState(false);
  let navigate = useNavigate();
  const states = useRecoilValue(statesInfoState);
  const [selectedState, setSelectedState] = useRecoilState(selectedInfoState);
  const onClickToggle = useCallback(() => {
    setToggle(!toggle);
  }, [toggle]);
  const chooseCity = (state: IState) => {
    setSelectedState(defaultCountry);

    setTimeout(() => {
      setSelectedState(state);
      navigate(`/${state.state}`);
    }, 100);
  };

  return (
    <Menu as="div" className="fixed top-4 right-4 sm:top-10 sm:right-10">
      <div>
        <Menu.Button
          className={`${secondaryClass} flex items-center`}
          onClick={onClickToggle}
        >
          <div>Select State</div>
          <div className="ml-1">
            {toggle ? (
              <ChevronUpIcon
                className="h-6 w-6 text-gray-900 text-xs"
                aria-hidden="true"
              />
            ) : (
              <ChevronDownIcon
                className="h-6 w-6 text-gray-900 text-xs"
                aria-hidden="true"
              />
            )}
          </div>
        </Menu.Button>
      </div>
      <Transition
        as={Fragment}
        show={toggle}
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95"
      >
        <Menu.Items className="absolute right-0 z-10 mt-2 w-56 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
          <div className="my-1 max-h-96 overflow-y-auto scrollbar-thin scrollbar-thumb-gray-800 scrollbar-track-gray-300">
            {states.map((state) => (
              <Menu.Item key={state.id}>
                {({ active }) => {
                  return (
                    <a
                      href="#"
                      rel="noopener noreferrer"
                      className={classNames(
                        active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                        'block px-4 py-4 text-sm'
                      )}
                      onClick={() => {
                        chooseCity(state);
                      }}
                    >
                      {state.title}
                    </a>
                  );
                }}
              </Menu.Item>
            ))}
          </div>
        </Menu.Items>
      </Transition>
    </Menu>
  );
}
