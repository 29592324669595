export type ConfigType = {
  mapbox: {
    token: string;
    style: string;
  };
  csvUrl: string;
  mapEntriesUrl: string;
};

const config: ConfigType = {
  mapbox: {
    token:
      'pk.eyJ1IjoidGhlY29kaW5nd2hhbGUiLCJhIjoiY2wxdThsbmw5MjZkYjNlbWptOGwycm1xNiJ9.TA9uQ9DfgKpuysCl5053Cw',
    style: 'mapbox://styles/thecodingwhale/clbh5suj9000214nqlnimmrvb',
  },
  csvUrl:
    'https://docs.google.com/spreadsheets/d/e/2PACX-1vTItA1iaCV8x8GAkv3FBjlqzTc4unRrkd2ZQc-WOU1VQNm_UCeF_wPoQkWqrRlgzRo3xUFuixiyfTCJ/pub?output=csv',
  mapEntriesUrl:
    'https://survivorspace.org/wp-json/wp/v2/map_entry?per_page=100&_embed',
};

export { config };
